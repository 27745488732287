import React, { useEffect, useRef, useState } from 'react';
import BearerMenu from './menu/BearerMenu';
import BearerMap from './map/BearerMap';
import VersionAgreement from './remoteconfig/VersionAgreement';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Tab, Tabs, Tooltip } from '@material-ui/core';
import LeftSidePanel from './order/LeftSidePanel';
import { useAppState, } from '../states/app-state';
import { observer, Observer } from 'mobx-react-lite';
import CheckServerRunningStates from './remoteconfig/CheckServerRunningStates';
import CheckDevicesPopup from './login/CheckDevicesPopup';
import ErrorsPopup from './errors/ErrorsPopup';
import Notifier from './Notifier';
import IpAlertMessage from './ipAlertMessage'; // added by Bearer Web Developer
import styles from './Scaffold.module.scss'; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer
import Split from '@uiw/react-split'; // added by Bearer Web Developer
import { setDefaultOrigin } from '../api/bearer-api'; // added by Bearer Web Developer
import { MenuDrawerContext, toggleMenuDrawer } from '../states/contexts/menuDrawer'; // added by Bearer Web Developer
import AddToHomeScreen from './addToHomeScreen'; // added by Bearer Web Developer
import FreeOrderPopup from './freeOrderPopup'; // added by Bearer Web Developer


const Scaffold: React.FC = observer(() => {

    const appState = useAppState();

    const sidebar = useRef(null);
    // added by Bearer Web Developer start
    const [logInPopupOpen, setLogInPopupOpen] = React.useState(false); // move login state from child component by Bearer Web Developer
    const handleLoginPopup = () => {
        setLogInPopupOpen(true);
    }
    // added by Bearer Web Developer end
    // useEffect(() => {

    // }, [appState.serverChecked])
    // added by Bearer Web Developer start (FreeOrder)
    const handleCloseFreeOrder = () => {
        appState?.freeOrder?.setOpen(false);
    }
    // added by Bearer Web Developer end (FreeOrder)
    // added by Bearer Web Developer start
    useEffect(() => {
        if (appState.serverChecked) {
            setDefaultOrigin();
        }
    }, [appState.serverChecked, appState.selectedOrder])
    // added by Bearer Web Developer end
    // added by Bearer Web Developer start
    const [tooltip, setTooltip] = useState<boolean>(false);
    const [swipe, setSwipe] = useState<boolean>(false);
    const swipable = () => { setSwipe(!swipe); }
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const valueMenuDrawer = { openDrawer, setOpenDrawer, swipe};
    useEffect(() => {
        if (appState.serverChecked) {
            if (appState.login.loggedin) {
                const { is_full_discount, fullDiscountUsedOnce } = appState?.fullDiscountAutomatically;
                appState?.freeOrder?.setOpen(is_full_discount && !fullDiscountUsedOnce);
            }
        }
    }, [appState.serverChecked]);
    useEffect(() => {
        if (appState.serverChecked) {
            setTooltip(true);
            setTimeout(() => { setTooltip(false) }, 3000);
        }
    },[appState.serverChecked,appState.login.loggedin]);
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return <>
        <div className={styles.mainContainer}>
            <AddToHomeScreen/> {/* AddToHomeScreen component added by Bearer Web Developer*/}
            <Notifier />
            {!appState.serverSync &&
                <div className={styles.circular_progress}>
                    <CircularProgress className={styles.loader} />
                </div>
            }
            {appState.serverSync &&
                <div>
                    {appState.serverChecked &&
                        <>
                            {/* FreeOrderPopup component in the following added by Bearer Web Developer*/}
                            {appState?.freeOrder?.open && <FreeOrderPopup open={appState?.freeOrder?.open} handleClose={handleCloseFreeOrder} />}
                            <div>
                                {/* MenuDrawerContext,Split wrapped by Bearer Web Developer start*/}
                                <MenuDrawerContext.Provider value={valueMenuDrawer}>
                                    <Split className={styles.container}
                                        renderBar={({ onMouseDown, ...props }) => {
                                            return (
                                                <div {...props} style={{ boxShadow: 'none', background: '#14438E' }}>
                                                    <div className={styles.adjustable} onMouseDown={onMouseDown}
                                                        style={{ background: '#14438E', height: "100vh", width: "8px" }}>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    >
                                        <div className={styles.orderForm_container}>
                                            <div className={styles.orderForm} ref={sidebar} >
                                                <div
                                                    className={styles.bearer_header}>
                                                    <div>
                                                        {
                                                            appState.login.loggedin ? 
                                                            <div className={styles.hamburger_menu}>
                                                                <span onClick={toggleMenuDrawer(true, setOpenDrawer)}>
                                                                    <img src="/hamburger_menu.png" alt="" />
                                                                </span>
                                                            </div>
                                                            :
                                                            <Button  onClick={handleLoginPopup} className={styles.login_mobile}  >
                                                                <img src="/login-mobile.webp" width={33} height={33} alt=""  />
                                                            </Button>
                                                        }
                                                    </div>
                                                    <div>
                                                        <img src="/bearer-header-logo-with-text.svg" alt="bearer" height={26} />
                                                    </div>
                                                    {/* added by Bearer Web Developer start*/}
                                                    {
                                                        <div className={styles.whatsapp}>
                                                            <a target='_blank' href={appState?.webAppRemoteConfigParameters?.whatsappLiveSupportNumber}>
                                                                <Tooltip title={"Whatsapp Live Chat"} arrow={true} open={tooltip}  
                                                                    classes={{ tooltip:styles.tooltip, arrow:styles.arrow }}
                                                                >
                                                                    <img className={styles.whatsapp_image} src="/whatsapp.svg" alt="" width={48} height={48} />
                                                                </Tooltip> 
                                                            </a>
                                                        </div>
                                                    }
                                                    {/* added by Bearer Web Developer end*/}
                                                </div>
                                                <OrderTabs />
                                                <LeftSidePanel />
                                                {/**  Put temp stuff here, it will be show below the scrolled div */}
                                            </div>
                                        </div>
                                        <div style={{ minWidth: 80, flex: 1 }}>
                                            <div className={styles.map_container}>
                                                <BearerMenu logInPopupOpen={logInPopupOpen} setLogInPopupOpen={setLogInPopupOpen} /> {/*  set parameters by Bearer Web Developer */}
                                                <div className={`${styles.map} ${!swipe ? styles.map_mobile : ''} ${!appState.login.loggedin ? styles.map_mobile_logout : ""}`}>
                                                    <div className={styles.swipable} onClick={swipable}>
                                                        {
                                                            swipe ?
                                                                <>
                                                                    <img src="/expand_more_black.png" alt="" />
                                                                    {/* <div className={styles.bar}></div> */}
                                                                </>
                                                                :
                                                                <div className={styles.swipe_up}>
                                                                    <img src="/swipe_up.png" alt="" />
                                                                    {/* <p>Swipe up to view the map</p> */}
                                                                    <p>Tap to view the map</p>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className={`${styles.bearer_map} ${!swipe ? styles.bearer_map_mobile : ''}`}>
                                                        <BearerMap />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Split>
                                </MenuDrawerContext.Provider>
                                {/* MenuDrawerContext,Split wrapped by Bearer Web Developer start*/}
                            </div>
                            {/* the following IpAlertMessage added by Bearer Web Developer */}
                            {appState.profile.ipAlertMessage && <IpAlertMessage />}
                            <VersionAgreement />
                            <SimpleMessagesDialog />
                            <ErrorsPopup />
                        </>
                    }
                    {!appState.serverChecked && <CheckServerRunningStates />}
                    <CheckDevicesPopup />
                </div>
            }
        </div>
    </>
    // modified by Bearer Web Developer end
});
export default Scaffold;



const OrderTabs: React.FC = observer(() => {
    const appState = useAppState();

    const handleSelect = (event: React.ChangeEvent<{}>, value: number) => {
        if (value >= 0) {
            appState.selectOrder(value);
        }
    };

    const ordersLimit = appState.profile.orderLimits!;
    const login = appState.login.loggedin;

    const handleAddNew = async () => {
        if ((login && appState.orders.length < ordersLimit) || (!login && appState.orders.length <= 0)) {
            await appState.addOrder();
            appState.selectOrder(appState.orders.length - 1);
        }

        return false;
    };
    React.useEffect(() => {
        if (!login && appState.orders.length === 0) {
            (async () => {
                await appState.addOrder();
                appState.selectOrder(appState.orders.length - 1);
            })();
        }
    }, [appState, login])
    // modified by Bearer Web Developer start
    return (
        <Observer>
            {() => (
                <Tabs
                    variant="scrollable"
                    scrollButtons={appState.orders.length > 2 ? "on" : "off"}
                    classes={{
                        root: styles.root_tabs,
                        flexContainer:
                            appState.orders.length > 2 ? '' : appState.orders.length > 1 ?
                                styles.flex_container_more_than_one_tabs : styles.flex_container
                    }}

                    ScrollButtonComponent={
                        (props) => {
                            return <div onClick={props.onClick}
                                className={`
                                    ${styles.scroll_buttons}
                                    ${props.direction === "right" ? styles.scroll_button_right : styles.scroll_button_left}`}
                            >
                                {<img src={`/expand_more_${props.direction === "right" ? "right" : "left"}.png`} alt="" />}
                            </div>
                        }
                    }
                    value={appState.selectedOrderIndex}
                    onChange={handleSelect}
                    className={styles.tabs}
                    TabIndicatorProps={{ hidden: true }}
                >
                    {appState.orders.map((o, i) => {
                        // modified by Bearer Web Developer start
                        const ReservedTab = appState?.orders[i]?.selectedReservedOrder; // added by Bearer Web Developer start
                        return <Tab key={i} style={{ margin: 0, padding: 0, textTransform: "none" }} className={appState.orders.length < 3 ? styles.tab_main : ""}
                            icon={
                                <div
                                    className={
                                        `${styles.tab} ${ReservedTab && appState.orders.length === 2 ? styles.reserved_two_tab :''} ${i === 0 ? styles.order_one : ''} ${appState.orders.length === 3 ? styles.more_tabs_three : appState.orders.length > 2 ? styles.more_tabs : appState.orders.length > 1 ? styles.more_than_one_tabs : styles.one_tab}`
                                    }
                                >{ ReservedTab ? ReservedTab?.client_order_number : ('Order ' + (i + 1))}</div>}
                            value={i} />
                            // modified by Bearer Web Developer end
                    })}
                    <Tab style={{ margin: 0, padding: 0, textTransform: "none" }} className={appState.orders.length < 3 ? styles.tab_main : ""}
                        icon={
                            <div
                                className={`${styles.add_order} ${appState.orders.length === 3 ? styles.add_order_more_tabs_three : appState.orders.length > 2 ? styles.add_order_more_tabs : appState.orders.length > 1 ? styles.add_order_more_than_one_tabs : styles.add_order_one_tab}`}
                            >
                                {appState.orders.length > 1 ? "+ Add" : "+ Add Order"}
                            </div>} value={-1} onClick={handleAddNew} />
                </Tabs>
            )}
        </Observer>
    )
    // modified by Bearer Web Developer end
})

const SimpleMessagesDialog: React.FC = observer(() => {
    const appState = useAppState();
    const simpleMessages = appState.simpleMessages;
    const open = simpleMessages.length > 0;
    const simpleMessage = open ? simpleMessages[0] : undefined;
    const handleRemoveMessage = () => {
        appState.removeLastMessage();
    }
    return (
        <>
            <Dialog open={open} PaperProps={{ style: { borderRadius: "10px" } }}>
                <DialogContent>
                    {simpleMessage && <DialogContentText>{simpleMessage.message}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleRemoveMessage}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    )
})



