import React from "react";
import log from 'loglevel';
import { useLocalObservable } from "mobx-react-lite";
import { flow, getEnv, getParent, Instance, onSnapshot, SnapshotIn, types, getParentOfType, cast, getSnapshot } from "mobx-state-tree";
import { analytics, BearerApi, getRemoteConfigData, NoCloudFunctionData, NoWebAppData, requestPermission, setDefaultOrigin, TAddCardResponse, TAddFavouritePlacesResponse, TBearerApi, TBearerCompletedTransport, TCancelTransportBeforeAcceptRequest, TCancelTransportBeforeAcceptResponse, TCardInformation, TCardProblem, TCheckCustomerLoginPermissionResponse, TCheckCustomerLoginPermissionSuccess, TChooseAnotherPaymentCardRequest, TChooseAnotherPaymentCardResponse, TcustomerCancelTransportAfterAcceptRequest, TCustomerCancelTransportAfterAcceptResponse, TCustomerCancelTransportAfterCancelByRiderRequest, TCustomerCancelTransportAfterCancelByRiderResponse, TCustomerCancelTransportBeforPaymentRequest, TCustomerCancelTransportBeforPaymentResponse, TCustomerData, TCustomerRateTransportRequest, TCustomerRateTransportResponse, TCustomerSkipRatingRequest, TCustomerSkipRatingResponse, TCustomerTransportHistoryResponse, TCustomerUpdateProfileRequest, TCustomerUpdateProfileResponse, TCycling, TDiscount, TDiscountRequest, TDiscountResponse, TDynamicPassword, TEmailTransportReceiptRequest, TEmailTransportReceiptResponse, TFareUpdated, TFavouritePlacesInfo, TGetCardResponse, TGetCustomerAppStateRequest, TGetCustomerAppStateResponse, TGetFavouritePlacesResponse, TLoginResponse, TPaymentAuthorizationRequest, TPaymentAuthorizationResponse, TPricingResponse, TRemoveCardResponse, TRemoveFavouritePlacesResponse, TRiderInfo, TRiding, TSearchRiderRequest, TSearchRiderResponse, TSetting, TSettingResponse, TTransport, TTransportAccepted, TTransportCanceledByRider, TTransportOption, TTransportRequestAgainRequest, TTransportRequestAgainResponse, TTransportResponse, TTransportsHistoryInfo, TTransportStateA, TTransportStateB, TTransportStateC, TTransportStateD, TTransportStateResponse, TUpdateAppResponse, TUpdateCustomerFcmTokenResponse, TWalking, WebAppRemoteConfigParameters } from "../api/bearer-api";
import { v4 as uuid_v4 } from "uuid";
import { extractMessages, extractOrders } from "./order-state";
import { RiderState } from "./riderState"; // added by Bearer Web Developer
import { UserState } from "./userState"; // added by Bearer Web Developer
import { error,log as logConsole } from "../app/log"; // added by Bearer Web Developer
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword, User } from "firebase/auth"; // added by Bearer Web Developer
import { isSupported } from "firebase/messaging"; // added by Bearer Web Developer
import { logEvent, setUserId } from "firebase/analytics"; // added by Bearer Web Developer
import { IAddToHomeScreen } from "../containers/addToHomeScreen/models/IAddToHomeScreen"; // added by Bearer Web Developer
import { ReservedOrdersState } from "./reservedOrdersState"; // added by Bearer Web Developer
import { ApiIntegrationState } from "./apiIntegrationState"; // added by Bearer Web Developer
import { TReservedOrderDetails } from "./reservedOrdersState/models/reservedOrderDetails"; // added by Bearer Web Developer
import { FullDiscountAutomaticallyState } from "./fullDiscountAutomaticallyState"; // added by Bearer Web Developer
import ReactPixel from 'react-facebook-pixel'; // added by Bearer Web Developer
import klaviyo from "./klaviyo"; // added by Bearer Web Developer
import getToday from "./klaviyo/getToday"; // added by Bearer Web Developer
import { FreeOrderState } from "./freeOrderState"; // added by Bearer Web Developer
export enum MessageEnum {
    Unknown = 'Unknown',
    ServerError = 'server Error',
    Error401 = '401',
    Error402 = '402',
    Error403 = '403',
    Error406 = '406',
    Error408 = '408',
    Error410 = '410',
    Error411 = '411',
    Error413 = '413',
    Error421 = '421',
    Error422 = '422',
    Error423 = '423',
    Error424 = '424',
    Error501 = '501',
}

export enum SelectEmailTransportReceiptEnum {
    MyEmail = 'MyEmail',
    Another = 'Another',
}

export enum PaymentStepEnum {
    SelectMethod = 'SelectMethod',
    RequestIsNotValid = 'RequestIsNotValid',
    FareUpdated = 'FareUpdated',
    CouponIsNotValid = 'CouponIsNotValid',
    SelectedCardHasProblem = 'SelectedCardHasProblem',
    DynamicPassword = 'DynamicPassword',
    PaymentSucceed = 'PaymentSucceed',
}

export enum SearchRiderEnum {
    Searching = 'Searching',
    RequestIsNotValid = 'RequestIsNotValid',
    CanceledByCustomer = 'CanceledByCustomer',
    RidersNotFound = 'RidersNotFound',
    RiderFound = 'transport_accepted',
    CallSupport = 'CallSupport',
    RiderCancelledRide = 'RiderCancelledRide',
    RiderNotFoundAfterTwoSearch = 'RiderNotFoundAfterTwoSearch',
}
export enum TransportStateEnum {
    CustomerCancelTransport = 'CustomerCancelTransport',
    ProcessingPayment = 'Processing Payment',
    RiderCancelledTransport = 'RiderCancelledTransport',
    RiderOnTheWayToOrigin = 'Rider on the way to Origin',
    RiderArrivesToOrigin = 'Arrived to Origin',
    RiderPickupParcel = 'Parcel Picked Up',
    RiderArrivesToDestination = 'Rider Arrived to Destination',
    RiderGetsPermissionToDropOff = 'Parcel Dropped Off at Destination',
    RiderCompletedTransport = 'Not Rated Yet',
    Alert = 'Show Alert',
    Authorised = 'Authorised',
    FirstTry = 'First Try',
    SecondTry = 'Second Try',
    ThreeDSFailed = '3DS Failed',
}
export enum ApprovalMethodEnum {
    Code = 'Code',
    None = 'None',
}
export enum ReasonForCancelRiderEnum {
    DidNotOnTime = 'Bearer didn’t arrive on time',
    ChangeMyMind = 'I’ve changed my mind',
    AddressIsWrong = 'My address is wrong',
    Others = 'Other reason',
}
export enum ParcelTypeEnum {
    Envelope = 'Envelope',
    SmallBox = 'Smallbox',
    MediumBox = 'MediumBox',
}

export enum TransportTypeEnum {
    cycling = 'cycling',
    riding = 'riding',
    walking = 'walking',
}


export interface Location {
    lat: number,
    lng: number,
}
export interface PlaceType {
    description: string;
    place_id?: string;
    reference?: string;
    structured_formatting?: {
        main_text: string;
        secondary_text: string;
        main_text_matched_substrings: {
            length: number;
            offset: number;
        }[];
    };
    location?: Location,
}

export interface PlaceTypeWithLocation extends PlaceType {
    location: Location,
}
export interface AddressType {
    description: string;
    location: Location,
}
export interface ParcelType {
    id: string,
    parcel_description: string,
    parcel_img_url: string,
    parcel_max_weight: number,
    parcel_min_weight: number,
    // vehicle_type: TransportTypeEnum[],
    vehicle_type: {
        walking: boolean,
        riding: boolean,
        cycling: boolean,
    };
    parcel_type: string,
    list_index_on_the_apps?: number, // added by Bearer Web Developer
    is_shown_for_orders?: boolean, // added by Bearer Web Developer
}
export interface TransportType {
    price_list_id: string,
    selected_mode_of_transport: string,
    origin_details: string,
    origin_phone_number: string,
    sender_name: string,
    destination_details: string,
    recipient_phone_number: string,
    recipient_name: string,
}

export const OriginState = types
    .model("OriginState", {
        address: types.maybeNull(types.frozen<AddressType>()),
        phone: types.optional(types.string, ''),
        name: types.optional(types.string, ''),
        moreDetails: types.optional(types.string, ''),
        editing: types.optional(types.boolean, true),
    })
    .views(self => ({
        get isEditable() {
            return true;
        },
        get isDisabled() {
            const riderInfo = getParentOfType(self, OrderState).transport.riderInfo;
            if (riderInfo) {
                return true;
            }
            return false;
        },
        get isComplete() {
            if (self.name && self.phone.length === 10 && self.name.length >= 2) {
                return true;
            }
            return false;
        },
    }))
    .actions(self => {
        return {
            setAddress(value: AddressType | null) {
                self.address = value;
            },
            setPhone(value: string) {
                self.phone = value;
            },
            setName(value: string) {
                self.name = value;
            },
            setMoreDetails(value: string) {
                self.moreDetails = value;
            },
            setEditing(value: boolean) {
                // added by Bearer Web Developer start
                if (getParent<IOrderState>(self)?.selectedReservedOrder) {
                    self.editing = false;
                    return;
                }
                // added by Bearer Web Developer end
                if (value && self.isEditable) {
                    self.editing = true;
                    const parent = getParent<IOrderState>(self);
                    parent.clearTransportData();
                    parent.destination.setEditing(false);
                    parent.parcelType.setEditing(false);
                    parent.transport.setEditing(false);
                } else {
                    self.editing = false;
                }
            },
            clear() {
                self.address = null;
                self.phone = '';
                self.name = '';
                self.moreDetails = '';
            }
        }
    })
export interface IOriginState extends Instance<typeof OriginState> { };

export const DestinationState = types
    .model("DestinationState", {
        address: types.maybeNull(types.frozen<AddressType>()),
        phone: types.optional(types.string, ''),
        name: types.optional(types.string, ''),
        moreDetails: types.optional(types.string, ''),
        approvalMethods: types.maybeNull(types.enumeration([...Object.values(ApprovalMethodEnum)])),
        editing: types.optional(types.boolean, false),
    })
    .views(self => ({
        get isEditable() {
            const origin = getParent<IOrderState>(self).origin;
            if (origin.isComplete) {
                return true;
            }
            return false;
        },
        get isDisabled() {
            const riderInfo = getParentOfType(self, OrderState).transport.riderInfo;
            if (riderInfo) {
                return true;
            }
            return false;
        },
        get isComplete() {
            if (self.phone.length === 10 && self.name.length >= 2 && self.approvalMethods) {
                return true;
            }
            return false;
        },
    }))
    .actions(self => {
        return {
            setAddress(value: AddressType | null) {
                self.address = value;
            },
            setPhone(value: string) {
                self.phone = value;
            },
            setName(value: string) {
                self.name = value;
            },
            setMoreDetails(value: string) {
                self.moreDetails = value;
            },
            setApprovalMethods(value: ApprovalMethodEnum) {
                self.approvalMethods = value;
            },
            setEditing(value: boolean) {
                // added by Bearer Web Developer start
                if (getParent<IOrderState>(self)?.selectedReservedOrder) {
                    self.editing = false;
                    return;
                }
                // added by Bearer Web Developer end
                if (value && self.isEditable) {
                    self.editing = true;
                    const parent = getParent<IOrderState>(self);
                    parent.clearTransportData();
                    parent.origin.setEditing(false);
                    parent.parcelType.setEditing(false);
                    parent.transport.setEditing(false);
                } else {
                    self.editing = false;
                }
            },
            clear() {
                self.address = null;
                self.phone = '';
                self.name = '';
                self.moreDetails = '';
                self.approvalMethods = null;

            }
        }
    })
export interface IDestinationState extends Instance<typeof DestinationState> { };


export const ParcelState = types
    .model("ParcelState", {
        type: types.maybeNull(types.frozen<ParcelType>()),
        editing: types.optional(types.boolean, false),
    })
    .views(self => ({
        get isEditable() {
            const origin = getParent<IOrderState>(self).origin;
            const destination = getParent<IOrderState>(self).destination;

            if (origin.isComplete && destination.isComplete) {
                return true;
            }
            return false
        },
        get isDisabled() {
            const riderInfo = getParentOfType(self, OrderState).transport.riderInfo;
            if (riderInfo) {
                return true;
            }
            return false;
        },
        get isComplete() {
            if (self.type) {
                return true;
            }
            return false;
        }
    }))
    .actions(self => {
        return {
            selectType(type: ParcelType) {
                self.type = type;
            },
            setEditing(value: boolean) {
                // added by Bearer Web Developer start
                if (getParent<IOrderState>(self)?.selectedReservedOrder) {
                    self.editing = false;
                    return;
                }
                // added by Bearer Web Developer end
                if (value && self.isEditable) {
                    self.editing = true;
                    const parent = getParent<IOrderState>(self);
                    parent.clearTransportOptions();
                    parent.origin.setEditing(false);
                    parent.destination.setEditing(false);
                    parent.transport.setEditing(false);
                } else {
                    self.editing = false;
                }
            },
            clear() {
                self.type = null;
            },
        }
    })
export interface IParcelState extends Instance<typeof ParcelState> { };


export const MessageState = types
    .model("MessageState", {
        id: types.string,
        message: types.string,
        sender_id: types.string,
        user_type: types.string, // user_type added by Bearer Web Developer
        timestamp: types.number,
        sent: types.optional(types.boolean, false),
        received: types.optional(types.boolean, false),
    })

    .views(self => ({
        get myId(): string | null {
            return getParentOfType(self, AppState).login.uid;
        },
        get riderAvatar(): string | undefined {
            return getParentOfType(self, TransportState).riderInfo?.rider_avatar_url;
        },
        get myAvatar(): string | undefined {
            return getParentOfType(self, ProfileState).customerData?.avatar_url;
        }
    }))
    .views(self => ({
        get isMyMessage(): boolean {
            return self.user_type === "customer"; // edited by Bearer Web Developer
        },
        get isRiderMessage(): boolean {
            return self.user_type === "rider"; // edited by Bearer Web Developer
        },
        // isAdminMessage added by Bearer Web Developer
        get isAdminMessage(): boolean {
            return self.user_type === "admin";
        },
    }))

    .actions(self => ({
        setSent(value: boolean) {
            self.sent = value;
        },
        setReceived(value: boolean) {
            self.received = value;
        },
    }))

export interface IMessageState extends Instance<typeof MessageState> { };
export interface IMessageStateSnapshot extends SnapshotIn<typeof MessageState> { };

export const ChatState = types
    .model("ChatState", {
        messages: types.array(MessageState),
        loaded: types.optional(types.boolean, false),
    })
    .views(self => ({
        get order(): IOrderState | null {
            return getParentOfType(self, OrderState);
        },
        get transportId(): string | undefined {
            return getParentOfType(self, OrderState).transport.value?.transport_id;
        },
    }))


    .actions(self => ({
        receiveMessage(message: IMessageStateSnapshot) {
            self.messages.push(message);
        },
        loadChatMessages() {
            const transportId = self.transportId;
            if (!transportId) {
                return;
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const orderState = getParentOfType(self, OrderState);
            api.loadChatMessagesFromFirebase(orderState, transportId);
            self.loaded = true;
        },
    }))
    .actions(self => ({
        sendMessage: flow(function* (text: string) {
            // console.log({text}) // added for test multiline message by Bearer Web Developer
            const sender_id = getParentOfType(self, AppState).login.uid!;
            const transportId = self.transportId;
            if (!transportId) {
                return;
            }
            const message = MessageState.create({ message: text, id: uuid_v4(), timestamp: new Date().getTime(), sender_id, user_type: "customer" });
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                // console.log({message:message.message }) // added for test multiline message by Bearer Web Developer
                yield api.sendMessage(message.message, message.sender_id, transportId, message.id, message.timestamp, message.user_type);
                message.setSent(true);
            } catch (err) {
                error(err); // modify by Bearer Web Developer
            }

        }),

        register() {
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const transportId = self.transportId;
            if (!transportId) {
                return;
            }
            const orderState = getParentOfType(self, OrderState);
            api.registerForChat(orderState, transportId);
            if (!self.loaded) {
                self.loadChatMessages();
            }
        },
    }))

export interface IChatState extends Instance<typeof ChatState> { };


export const RatingState = types
    .model("RatingState", {
        safeDelivery: types.maybeNull(types.number),
        politeness: types.maybeNull(types.number),
        speed: types.maybeNull(types.number),
    })
    .views(self => ({
        get order(): IOrderState | null {
            return getParentOfType(self, OrderState);
        },
    }))
    .actions(self => ({
        setSafeDelivery(value: number | null) {
            self.safeDelivery = value;
        },
        setPoliteness(value: number | null) {
            self.politeness = value;
        },
        setSpeed(value: number | null) {
            self.speed = value;
        }
    }))
    .actions(self => ({
        customerRateTransport: flow<boolean, []>(function* () {
            if (!self.order?.transport.value) return false;
            if (!self.safeDelivery) return false;
            if (!self.politeness) return false;
            if (!self.speed) return false;

            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const request: TCustomerRateTransportRequest = {
                transport_id: self.order.transport.value.transport_id,
                safe_delivery: self.safeDelivery,
                politeness: self.politeness,
                being_ontime: self.speed,
            }
            const result: TCustomerRateTransportResponse = yield api.customerRateTransport(request)
            try {
                if (result.code === 200) {
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Transport Rated
                    // App Event customerRateTransport = code 200
                    logEvent(analytics,"transport_rated",{
                        transport_id: self.order.transport.value.transport_id
                    });
                    // added by Bearer Web Developer end
                    const appState = getParentOfType(self, AppState);
                    // added by Bearer Web Developer start
                    // klaviyo event start Transport Rated -> 'TransportRated' 
                    // Track a user's behavior - create an event in Klaviyo
                    klaviyo.createEvent({
                        email: appState?.profile?.email,
                        event: "Transport Rated"
                    });
                    // klaviyo event end
                    // added by Bearer Web Developer end
                    appState.selectedOrder?.transport.removeMyOrder()
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!',
                        error: MessageEnum.Error401
                    });
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402,
                    });
                } else if (result.code === 410) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error410
                    });
                }
                else if (result.code === 421) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error421
                    });
                }
                else if (result.code === 424) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error424
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }
                return true;
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;

        }),
        customerSkipRating: flow(function* () {
            if (!self.order?.transport.value) return false;
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const request: TCustomerSkipRatingRequest = {
                transport_id: self.order.transport.value.transport_id,
            }
            const result: TCustomerSkipRatingResponse = yield api.customerSkipRating(request);
            try {
    
                if (result.code === 200) {
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Transport Rating Skipped
                    // App Event customerSkipRating = code 200
                    logEvent(analytics,"transport_rating_skipped",{
                        transport_id: self.order.transport.value.transport_id
                    });
                    // added by Bearer Web Developer end
                    const appState = getParentOfType(self, AppState);
                    // added by Bearer Web Developer start
                    // klaviyo event start Transport Rating Skipped -> 'TransportRatingSkipped' 
                    klaviyo.createEvent({
                        email: appState?.profile?.email,
                        event: "Transport Rating Skipped"
                    });
                    // klaviyo event end
                    // added by Bearer Web Developer end
                    appState.selectedOrder?.transport.removeMyOrder()

                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402,
                    });
                } else if (result.code === 410) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error410,
                    });
                } else if (result.code === 421) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error421,
                    });
                } else if (result.code === 424) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error424,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown,
                })
            }
        }),
    }))

export interface IRatingState extends Instance<typeof RatingState> { };



export const CancelState = types
    .model("CancelState", {
        otherReason: types.optional(types.string, ''),
        reasonForCancelRider: types.maybeNull(types.enumeration([...Object.values(ReasonForCancelRiderEnum)])),
    })
    .views(self => ({
        get order(): IOrderState | null {
            return getParentOfType(self, OrderState);
        },
        get reasonText(): string {
            if (self.reasonForCancelRider === ReasonForCancelRiderEnum.Others) {
                return self.otherReason;
            } else {
                return CancelReasonLabel(self.reasonForCancelRider);
            }
        },
    }))
    .actions(self => ({
        setOtherReason(value: string) {
            self.otherReason = value;
        },
        setReasonForCancelRider(value: ReasonForCancelRiderEnum) {
            self.reasonForCancelRider = value;
        },
    }))

export interface ICancelState extends Instance<typeof CancelState> { };

export type TCancelInfo = {}


export const TransportState = types
    .model("TransportState", {
        value: types.maybeNull(types.frozen<TTransport>()),
        editing: types.optional(types.boolean, false),
        riderInfo: types.maybeNull(types.frozen<TRiderInfo>()),
        rating: types.optional(RatingState, {}),
        step: types.maybeNull(types.enumeration([...Object.values(TransportStateEnum)])),
        shouldInformServerOnCancel: types.optional(types.boolean, false),
        cancelInfo: types.maybeNull(types.frozen<TCancelInfo>()),
    })
    .views(self => ({
        get appState(): IAppState {
            return getParentOfType(self, AppState);
        },
        get isEditable() {
            const origin = getParent<IOrderState>(self).origin;
            const destination = getParent<IOrderState>(self).destination;
            const parcelType = getParent<IOrderState>(self).parcelType;
            if (origin.isComplete && destination.isComplete && parcelType.isComplete) {
                return true;
            }
            return false
        },
        get isDisabled() {
            const riderInfo = getParentOfType(self, OrderState).transport.riderInfo;
            if (riderInfo) {
                return true;
            }
            return false;
        },
        get isComplete() {
            if (self.value) {
                return true;
            }
            return false;
        }
    }))
    .actions(self => {
        return {
            // added by Bearer Web Developer start
            setValue(value : TTransport) {
                self.value = value
            },
             // added by Bearer Web Developer end
            setCustomerCancel(info: TCancelInfo) {
                self.step = TransportStateEnum.CustomerCancelTransport;
                self.cancelInfo = info;
            },
            setRiderCancelTransport() {
                self.step = TransportStateEnum.RiderCancelledTransport;
            },
            setRiderOnTheWayToOrigin() {
                self.step = TransportStateEnum.RiderOnTheWayToOrigin;
            },
            setRiderArriveToOrigin() {
                self.step = TransportStateEnum.RiderArrivesToOrigin;
            },
            setRiderPickedUpParcel() {
                self.step = TransportStateEnum.RiderPickupParcel;
            },
            setRiderArrivedToDestination() {
                self.step = TransportStateEnum.RiderArrivesToDestination;
            },
            setRiderGetsPermissionToDropOf() {
                self.step = TransportStateEnum.RiderGetsPermissionToDropOff;
            },
            setRiderCompletedTransport() {
                self.step = TransportStateEnum.RiderCompletedTransport;
            },
            setP() {
                self.step = null
            },
            setRiderFound(riderInfo: TRiderInfo) {
                // if (self.riderInfo) return;
                self.riderInfo = riderInfo;
            },
            setNewTransportId(new_transport_id: string) {
                const value = JSON.parse(JSON.stringify(self.value));//TODO check why we need it?!
                value.transport_id = new_transport_id;
                self.value = value;
                self.riderInfo = null;
                self.step = null;
                self.cancelInfo = null;
            },
            setShouldInformServerOnCancel(value: boolean) {
                self.shouldInformServerOnCancel = value;
            },
        }
    })
    .actions(self => {
        return {
            setEditing(value: boolean) {
                // added by Bearer Web Developer start
                if (getParent<IOrderState>(self)?.selectedReservedOrder) {
                    self.editing = false;
                    return;
                }
                // added by Bearer Web Developer end
                if (value && self.isEditable) {
                    self.editing = true;
                    const parent = getParent<IOrderState>(self);
                    parent.origin.setEditing(false);
                    parent.destination.setEditing(false);
                    parent.parcelType.setEditing(false);
                } else {
                    self.editing = false;
                }
            },
            clear() {
                self.value = null;
            },
            async removeMyOrder() {
                const orderState = getParentOfType(self, OrderState);
                const appState: IAppState = self.appState;
                appState.selectOrder(-1);
                appState.removeOrder(orderState);
                if (appState.orders.length === 0) {
                    await appState.addOrder();
                }
                appState.selectOrder(0);
                return;

            },
        }
    })


    .actions(self => ({
        customerCancelTransportAfterAccept: flow(function* (cancelState: { reason: ReasonForCancelRiderEnum, othersText: string }) {
            if (!self.value) {
                return false;
            }
            const request: TcustomerCancelTransportAfterAcceptRequest = {
                transport_id: self.value.transport_id,
                cancelation_reason: cancelState.reason === ReasonForCancelRiderEnum.Others ?
                    cancelState.othersText : cancelState.reason,
            }

            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const result: TCustomerCancelTransportAfterAcceptResponse = yield api.customerCancelTransportAfterAccept(request);
            // added by Bearer Web Developer start
            // Firebase Analytics Event Name Order Cancelled After Rider Acceptance
            // App Event customerCancelTransportAfterAccept is called
            logEvent(analytics,"order_cancelled_after_acceptance",{
                transport_id: self.value.transport_id,
                reason: cancelState.reason === ReasonForCancelRiderEnum.Others ?
                    cancelState.othersText : cancelState.reason,
            });
            // added by Bearer Web Developer end
            try {
                if (result.code === 200) {
                    api.detachTransportState(getParent<IOrderState>(self)) // added by Bearer Web Developer
                    const cancelInfo = {};
                    self.setCustomerCancel(cancelInfo);
                    self.removeMyOrder()
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402,
                    });
                }
                else if (result.code === 410) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error410,
                    });
                }
                else if (result.code === 421) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error421,
                    });
                }
                // else if (result.code === 422) {
                //     const appState = getParentOfType(self, AppState);
                //     appState.setErrorMessage({
                //         message: result.message || 'unknown error!', error: MessageEnum.Error422,
                //     });
                // }
                else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }
                return true;
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;
        }),
    }))

export interface ITransportState extends Instance<typeof TransportState> { };



export const SettingState = types
    .model("SettingState", {
        account_updates: types.optional(types.enumeration(["push_not", "text_msg"]), 'push_not'),
        news_marketing_messages: types.optional(types.enumeration(["off", "on"]), 'on'),
        receive_payment_receipt: types.optional(types.enumeration(["automatic", "manual"]), 'automatic'),
        settingOnServer: types.maybeNull(types.frozen<TSetting>()),
    })
    .views(self => ({
        get doesItNeedPost(): boolean {
            if (self.settingOnServer &&
                (
                    self.receive_payment_receipt !== self.settingOnServer.receive_payment_receipt
                    || self.account_updates !== self.settingOnServer.account_updates
                    || self.news_marketing_messages !== self.settingOnServer.news_marketing_messages
                )
            ) {
                return true;
            }
            return false;
        },
    }))
    .actions(self => {
        return {
            setSetting(value: TSetting) {
                self.settingOnServer = value;
                self.receive_payment_receipt = value.receive_payment_receipt;
                self.account_updates = value.account_updates;
                self.news_marketing_messages = value.news_marketing_messages
            },
            setReceivePaymentReceiptAutomatic() {
                self.receive_payment_receipt = 'automatic';
            },
            setReceivePaymentReceiptManual() {
                self.receive_payment_receipt = 'manual';
            },
            setAccountUpdatesPushNot() {
                self.account_updates = 'push_not';
            },
            setAccountUpdateText() {
                self.account_updates = 'text_msg';
            },
            setNewsMarketingMessagesPushNot() {
                self.news_marketing_messages = 'off';
            },
            setNewsMarketingMessagesEmail() {
                self.news_marketing_messages = 'on';
            },
        }
    })
    .actions(self => {
        return {
            postSetting: flow<boolean, []>(function* () {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                try {
                    const settings = {
                        receive_payment_receipt: self.receive_payment_receipt,
                        account_updates: self.account_updates,
                        news_marketing_messages: self.news_marketing_messages,

                    }
                    // set type of settings as TSetting by Bearer Web Developer
                    const result: TSettingResponse = yield api.postSetting(settings as TSetting);

                    if (result.code === 200) {
                        self.setSetting(settings as TSetting)
                        return true;
                    } else if (result.code === 401) {
                        const appState = getParentOfType(self, AppState);
                        appState.setErrorMessage({
                            message: result.message || 'unknown error', error: MessageEnum.Error401,
                        });
                    } else if (result.code === 500) {
                        const appState = getParentOfType(self, AppState);
                        appState.setErrorMessage({
                            message: result.message || 'unknown error', error: MessageEnum.ServerError,
                        });
                    }
                } catch (err: any) {
                    error(err); // modify by Bearer Web Developer
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: err?.message || 'unknown error!',
                        error: MessageEnum.Unknown
                    })
                }
                return false;
            }),
        }
    })
export interface ISettingState extends Instance<typeof SettingState> { };



export const FavouritePlacesState = types
    .model("FavouritePlacesState", {
        favouritePlacesOptions: types.maybeNull(types.array(types.frozen<TFavouritePlacesInfo>())),
        favouritePlacesOptionsUpdate: types.maybeNull(types.Date),
        favouritePlacesFormOpen: types.optional(types.boolean, false), // added by Bearer Web Developer
        defaultOriginFormOpen: types.optional(types.boolean, false), // added by Bearer Web Developer
    })
    .actions(self => ({
        setPlaces: (favourite_places: { [id: string]: TFavouritePlacesInfo }) => {
            const resultArray = Object.keys(favourite_places).map(id => favourite_places[id]);
            self.favouritePlacesOptions = cast(resultArray);
            self.favouritePlacesOptionsUpdate = new Date();
        },
        // added by Bearer Web Developer start
        setFavouritePlacesFormOpen(value: boolean) {
            self.favouritePlacesFormOpen = value;
        },
        setDefaultOriginFormOpen(value: boolean) {
            self.defaultOriginFormOpen = value;
        },
        // added by Bearer Web Developer end
    }))
    .actions(self => ({
        prepareFavouritePlacesOptions: flow<boolean, [boolean]>(function* (forceRefresh: boolean) {
            const now = new Date();
            if (!forceRefresh && self.favouritePlacesOptionsUpdate && now.getTime() - self.favouritePlacesOptionsUpdate.getTime() < 30 * 60 * 1000) {
                return true;
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: TGetFavouritePlacesResponse = yield api.getFavouritePlaces();
           
                if (result.code === 200) {
                    self.setPlaces(result.favourite_places)
                    return true;
                } else if (result.code === 201) {
                    self.setPlaces({});
                } else if (result.code === 401) {
                    self.setPlaces({});
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 500) {
                    self.setPlaces({});
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }
            } catch (err: any) {
                self.setPlaces({});
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;
        }),
    }))
    .actions(self => ({
        removeFavouritePlaces: flow(function* (favourite_place_id: string) {
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: TRemoveFavouritePlacesResponse = yield api.removeFavouritePlaces({
                    favourite_place_id,
                });
  
                if (result.code === 200) {
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Favourite Place Removed
                    // App Event removeFavouritePlaces = code 200
                    logEvent(analytics,"favourite_place_removed");
                    // added by Bearer Web Developer end
                    const index = self.favouritePlacesOptions?.findIndex(p => p.id === favourite_place_id) ?? -1;
                    if (index !== -1) {
                        self.favouritePlacesOptions?.splice(index, 1);
                    }
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                    
                } else if (result.code === 435) { // 435 code added by Bearer Web Developer
                    // (with top condition) added by Bearer Web Developer start 
                    const appState = getParentOfType(self, AppState);
                    appState.setSnackMessage({
                        message: result.message || 'unknown error!',
                        handleClick: async () => {
                           await appState.favouritePlaces.prepareFavouritePlacesOptions(true);
                        },
                        hasLoadingForHandleClick: true
                    });
                    // added by Bearer Web Developer end
                }  else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown,
                })
            }
        }),
        // is_set_as_default_origin parameter added by Bearer Web Developer
        addFavouritePlaces: flow<boolean, [IFavouritePlacesFormState,boolean]>(function* (newPlace: IFavouritePlacesFormState,is_set_as_default_origin: boolean) {
            if (!newPlace.address) {
                return false
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const request = {
                    place_name: newPlace.placeName,
                    address: newPlace.address?.description,
                    location_lat: newPlace.address.location.lat,
                    location_lng: newPlace.address.location.lng,
                    details: newPlace.placeDetails,
                    is_set_as_default_origin // added by Bearer Web Developer
                }
                const result: TAddFavouritePlacesResponse = yield api.addFavouritePlaces(request);
            
                if (result.code === 200) {
                    yield self.prepareFavouritePlacesOptions(true);
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Favourite Place Added
                    // App Event addFavouritePlaces = code 200
                    logEvent(analytics,"favourite_place_added");
                    // added by Bearer Web Developer end
                    return true;
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;
        }),
    }))

export interface IFavouritePlacesState extends Instance<typeof FavouritePlacesState> { };



export const ProfileState = types
    .model("ProfileState", {
        loaded: types.optional(types.boolean, false),
        loading: types.optional(types.boolean, false),
        businessName: types.optional(types.string, ""),
        firstName: types.optional(types.string, ""),
        lastName: types.optional(types.string, ""),
        phoneNumber: types.optional(types.string, ""),
        email: types.optional(types.string, ""),
        avatar: types.optional(types.string, ""),
        setting: types.optional(SettingState, {}),
        latest_tc_pp_vers_signed: types.optional(types.string, ""),
        agreementNeeded: types.optional(types.boolean, false),
        customerData: types.maybeNull(types.frozen<TCustomerData>()),
        orderLimits: types.maybeNull(types.number),
        ipAlertMessage: types.optional(types.boolean, false), // added by Bearer Web Developer
    })
    .views(self => ({
        get uid(): string | null {
            const appState = getParent<IAppState>(self);
            return appState.login.uid;
        }
    }))
    .actions(self => {
        return {
            handleVersionControl: flow(function* () {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();


                try {
                    const result: boolean = yield api.isAgreementNeeded(self.latest_tc_pp_vers_signed)

                    if (result) {
                        self.agreementNeeded = true;
                    }
                } catch (err: any) {
                    error(err); // modify by Bearer Web Developer
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: err?.message || 'unknown error!',
                        error: MessageEnum.Unknown
                    })
                }
            }),
        }
    })

    .actions(self => {
        return {
            setBusinessName(value: string) {
                self.businessName = value;
            },
            setFirstName(value: string) {
                self.firstName = value;
            },
            setLastName(value: string) {
                self.lastName = value;
            },
            setPhoneNumber(value: string) {
                self.phoneNumber = value;
            },
            setEmail(value: string) {
                self.email = value;
            },
            setAvatar(value: string) {
                self.avatar = value;
            },
            setOrdersLimit(value: number) {
                self.orderLimits = value;
            },
            setData(result: TCheckCustomerLoginPermissionSuccess) {
                self.businessName = result.business_name;
                self.firstName = result.given_name;
                self.lastName = result.last_name;
                self.phoneNumber = "0" + result.mobile_number.substring(3, result.mobile_number.length);
                self.email = result.email_address;
                self.avatar = result.avatar_url;
                self.setting.setSetting(result.settings);
                self.latest_tc_pp_vers_signed = result.latest_tc_pp_vers_signed;
                self.loaded = true;
                self.orderLimits = result.order_limits || 5; // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.favouritePlaces.setPlaces(result.favourite_places);
            },

            updateProfile: flow(function* (profileFields: { firstName: string, lastName: string, phoneNumber: string }) {
                const request: TCustomerUpdateProfileRequest = {
                    given_name: profileFields.firstName,
                    last_name: profileFields.lastName,
                    mobile_number: profileFields.phoneNumber,
                    avatar_url: self.avatar,
                }
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                try {
                    const result: TCustomerUpdateProfileResponse = yield api.customerWebAppUpdateProfile(request);
             
                    if (result.code === 200) {
                        // self.firstName = profileFields.firstName;
                        // self.lastName = profileFields.lastName;
                        // self.phoneNumber = profileFields.phoneNumber;
                        // klaviyo profile update start User Profile Update -> After Code 200 from User Profile Update Function
                        yield klaviyo.createOrUpdateProfile({
                            type: 'profile',
                            attributes: {
                                email: self?.email,
                                properties: {
                                    external_id: self?.uid?.slice(-7) || '',
                                    phone_number: profileFields?.phoneNumber, 
                                    first_name: profileFields?.firstName, 
                                    last_name: profileFields?.lastName, 
                                }
                            },
                        });
                        // klaviyo profile update end
                        return true;
                    } else if (result.code === 401) {
                        const appState = getParentOfType(self, AppState);
                        appState.setErrorMessage({
                            message: result.message || 'unknown error', error: MessageEnum.Error401,
                        });
                    } else if (result.code === 500) {
                        const appState = getParentOfType(self, AppState);
                        appState.setErrorMessage({
                            message: result.message || 'unknown error', error: MessageEnum.ServerError,
                        });
                    }
                } catch (err: any) {
                    error(err); // modify by Bearer Web Developer
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: err?.message || 'unknown error!',
                        error: MessageEnum.Unknown
                    })
                }
            }),
            updateAvatar: flow(function* (image: Blob) {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                try {
                    if (!self.uid) return;
                    const result = yield api.uploadProfileImage(self.uid, image);
                    self.avatar = result;
                } catch (err: any) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: err?.message || 'unknown error!',
                        error: MessageEnum.Unknown
                    })
                }
            }),
            deleteProfileImage: flow(function* () {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                try {
                    if (!self.uid) return;
                    yield api.deleteProfileImage(self.uid);
                    self.avatar = "";
                    // yield api.customerWebAppUpdateProfile({
                    //     given_name: self.lastName,
                    //     last_name: self.lastName,
                    //     mobile_number: self.phoneNumber,
                    //     avatar_url: self.avatar,
                    // });

                } catch (err: any) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: err?.message || 'unknown error!',
                        error: MessageEnum.Unknown
                    })
                }
            })

        }
    })
    .actions((self) => {
        let promise: Promise<TCheckCustomerLoginPermissionResponse | void> | undefined = undefined; // void type added by Bearer Web Developer
        return {
            fetchProfile: flow(function* () {
                if (self.loaded) return;
                if (!self.uid) return;

                if (promise) {
                    yield promise;
                    return;
                }
                self.loading = true;
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                try {
                    promise = api.checkCustomerLoginPermission();
                    const result: TCheckCustomerLoginPermissionResponse = yield promise;
               
                    if (result.code === 200) {
                        self.setData(result);
                        // console.log({fetchProfile: result}) // added by Bearer Web Developer
                        self.latest_tc_pp_vers_signed = result.latest_tc_pp_vers_signed
                    }
                } catch (err: any) {
                    error(err); // modify by Bearer Web Developer
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: err?.message || 'unknown error!',
                        error: MessageEnum.Unknown
                    })

                } finally {
                    self.loading = false;
                }
            }),
        }
    })
    .actions(self => {
        return {
            handleUpdateAgreedVersion: flow(function* () {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                const result: TUpdateAppResponse = yield api.updateAgreedAppVersion();
                // result?.alertMessage condition added by Bearer Web Developer in this part start
                if (result?.ipAlertMessage) {
                    self.ipAlertMessage = true;
                } else {
                   
                    if (result?.code === 200) {
                        self.agreementNeeded = false;
                        self.fetchProfile();
                    } else if (result?.code === 401) {
                        const appState = getParentOfType(self, AppState);
                        appState.setErrorMessage({
                            message: result?.message || 'unknown error!', error: MessageEnum.Error401,
                        });
                    } else if (result?.code === 500) {
                        const appState = getParentOfType(self, AppState);
                        appState.setErrorMessage({
                            message: result?.message || 'unknown error!', error: MessageEnum.ServerError,
                        });
                    }
                }
                // result?.alertMessage condition ... end
            }),
        }
    })

export interface IProfileState extends Instance<typeof ProfileState> { };


export const DiscountState = types
    .model("DiscountState", {
        discountCode: types.optional(types.string, ""),
        value: types.maybeNull(types.frozen<TDiscount>()),
        fullDiscountUser: types.optional(types.boolean, false), // added by Bearer Web Developer
    })
    .actions(self => ({
        setDiscountCode(value: string) {
            self.discountCode = value;
        },
        // added by Bearer Web Developer start
        setDiscountValue(value: TDiscount | null) {
            self.value = value;
        },
        setFullDiscountUser(value: boolean) {
            self.fullDiscountUser = value;
        },
        // added by Bearer Web Developer end
    }))

export interface IDiscountState extends Instance<typeof DiscountState> { };


export const PaymentState = types
    .model("PaymentState", {
        open: types.optional(types.boolean, false),
        step: types.maybeNull(types.enumeration([...Object.values(PaymentStepEnum)])),
        cardOptions: types.maybeNull(types.array(types.frozen<TCardInformation>())),
        cardOptionsUpdate: types.maybeNull(types.Date),
        selectedCard: types.maybeNull(types.frozen<TCardInformation>()),
        fareUpdateData: types.maybeNull(types.frozen<TFareUpdated>()),
        dynamicPassword: types.maybeNull(types.frozen<TDynamicPassword>()),
        cardProblem: types.maybeNull(types.frozen<TCardProblem>()),
    })
    .views(self => ({
        get order(): IOrderState | null {
            return getParentOfType(self, OrderState);
        },
    }))

    .actions(self => ({
        clear() {
            self.selectedCard = null;
            self.step = null;
            self.open = false;
        },
        doFareUpdate() {
            self.step = PaymentStepEnum.SelectMethod;
            self.order!.selectedTransportOption!.price = self.fareUpdateData!.transport_original_price;
        },
        removeCouponId() {
            self.step = PaymentStepEnum.SelectMethod;
            self.order!.clearDiscount();
        },
        ShowCardProblem() {
            self.step = PaymentStepEnum.SelectMethod;
        },
        setOpen(order: IOrderState) {
            self.open = true;
            self.step = PaymentStepEnum.SelectMethod;
        },
        setClose() {
            self.open = false;
        },
        selectCard(value: TCardInformation) {
            self.selectedCard = value;
        },
        prepareCardOptions: flow<boolean, [boolean,string?]>(function* (forceRefresh: boolean, cardId) { // modify by Bearer Web Developer (cardId argument added)
            const now = new Date();
            if (!forceRefresh && self.cardOptionsUpdate && now.getTime() - self.cardOptionsUpdate.getTime() < 5 * 60 * 1000) {
                return true;
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: TGetCardResponse = yield api.getCard();
         
                if (result.code === 200) {
                    self.selectedCard = null;
                    self.cardOptions = null;
                    // modify by Bearer Web Developer start
                    if (cardId) {
                        self.cardOptions = cast(result.stripeData.data.map(info=>{
                            // console.log({cardId ,id: info?.id})
                            // console.log({info})
                          return  {...info,isNew: cardId === info?.id}
                        }
                        ));
                    } else {
                        self.cardOptions = cast(result.stripeData.data); 
                    }
                    // modify by Bearer Web Developer end
                    self.cardOptionsUpdate = now;
                    return true;
                } else if (result.code === 401) {
                    self.cardOptions = cast([]);
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 501) {
                    self.cardOptions = cast([]);
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error501,
                    });
                } else if (result.code === 500) {
                    self.cardOptions = cast([]);
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }
            } catch (err: any) {
                self.cardOptions = cast([]);
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;
        }),
    }))
    .actions(self => ({
        addCard: flow(function* (cardToken: string,cardId: string) { // modify by Bearer Web Developer (cardId added)
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: TAddCardResponse = yield api.addCard({
                    cardToken,
                });
          
                if (result.code === 200) {
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Payment Method Added
                    // App Event addStripeCard = code 200
                    logEvent(analytics,"new_payment_method_added");
                    // the fallowing "AddPaymentInfo" (Facebook Ads default event)
                    const { environmentCode } = yield getRemoteConfigData();
                    if (environmentCode === "PRODUCTION") {
                        ReactPixel.trackSingle('3587653761499122', 'AddPaymentInfo'); // For tracking custom events.
                    }
                    // added by Bearer Web Developer end
                    self.prepareCardOptions(true,cardId); // modify by Bearer Web Developer (cardId added)
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setSnackMessage({
                        message: result.message || 'unknown error!',
                    });
                } else if (result.code === 501) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error501,
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
        }),

        removeCard: flow(function* (cardToken: string) {
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: TRemoveCardResponse = yield api.removeCard({
                    cardToken,
                });
      
                if (result.code === 200) {
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Payment Method Removed
                    // App Event removeStripeCard = code 200
                    logEvent(analytics,"payment_method_removed");
                    // added by Bearer Web Developer end
                    self.prepareCardOptions(true);
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                } else if (result.code === 501) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error501,
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown,
                })
            }
        }),
    }))

    .actions(self => ({
        paymentAuthorization: flow<boolean, []>(function* () {
            if (!self.order?.transport.value) {
                return false;
            }
            // modified by Bearer Web Developer start
            const fullDiscountUser  = self.order?.discount?.fullDiscountUser; 
            if (!self.selectedCard && !fullDiscountUser) {
                return false;
            }
            // modified by Bearer Web Developer end
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                // modified by Bearer Web Developer start
                const request: TPaymentAuthorizationRequest = {
                    cardToken: fullDiscountUser ? "" : (self?.selectedCard?.id || ""),
                    device_type: "web",
                    transport_id: self.order.transport.value.transport_id,
                    coupon_id: self.order.discount?.value?.coupon_id || undefined,
                    is_full_discount: fullDiscountUser
                }
                // console.log({paymentAuthorizationrequestData:request})
                // modified by Bearer Web Developer end
                const result: TPaymentAuthorizationResponse = yield api.paymentAuthorization(request);
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name FREE Order (100% Promo Code Used for Payment)
                // App Event stripePayment is called && is_full_discount == true
                if (self.order?.discount.value?.transport_receivable_price == 0) { // Added by Amir Siabi 26 Aug 2024
                    logEvent(analytics,"free_order_placed", {
                        transport_id: self.order.transport?.value?.transport_id,
                        original_price: self.order.transport?.value?.transport_original_price,
                    })
                    // the fallowing "First Free Order" (Custom Event)
                    const { environmentCode } = yield getRemoteConfigData(); // added by Bearer Web Developer
                    if (environmentCode === "PRODUCTION") {
                        ReactPixel.trackSingleCustom('3587653761499122', 'FreeOrderPlaced'); // For tracking custom events.
                    }
                    // added by Bearer Web Developer end
                    // added by Bearer Web Developer start
                    // klaviyo event start First Free Order Placed after Tap on Search for Free -> 'FreeOrderPlaced' 
                    const appState = getParentOfType(self, AppState);
                    // Track a user's behavior - create an event in Klaviyo
                    klaviyo.createEvent({
                        email: appState?.profile?.email,
                        event: "Free Order Placed"
                    });
                    // klaviyo event end
                    // added by Bearer Web Developer end
                }   // Added by Amir Siabi 26 Aug 2024
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name Promo Code Used for Payment
                // App Event stripePayment is called && Discount Code is Used
                if (self.order.discount?.value?.coupon_id) {
                    const appState = getParentOfType(self, AppState);
                    const { order } = appState.selectedOrder!.payment;
                    const discountedPrice = order?.discount.value?.transport_receivable_price;
                    logEvent(analytics,"promo_code_used", {
                        transport_id: self.order.transport?.value?.transport_id,
                        original_price: self.order.transport?.value?.transport_original_price,
                        receivable_price: discountedPrice?.toString()
                    })
                    // added by Bearer Web Developer start
                    // klaviyo event start Promo Code Used for Payment -> 'PromoCodeUsed'  
                    // Track a user's behavior - create an event in Klaviyo
                    klaviyo.createEvent({
                        email: appState?.profile?.email,
                        event: "Promo Code Used"
                    });
                    // klaviyo event end
                    // added by Bearer Web Developer end
                }
                // added by Bearer Web Developer end
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name First Payment by a new Payment Method
                // App Event stripePayment is called USING Newly Added Card
                if (self.selectedCard?.isNew) { // check card is new
                    logEvent(analytics,"payment_using_a_new_card", {
                        transport_id: self.order.transport?.value?.transport_id,
                    })
                }
                // added by Bearer Web Developer end
                if (result.code === 200) {
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Order (Payment Authorised, Search Started)
                    // App Event stripePayment = code 200
                    if (self.order?.discount.value?.transport_receivable_price != 0) { // Added by Amir Siabi 26 Aug 2024
                        logEvent(analytics,"new_order_payment_authorised", {
                            transport_id: self.order.transport.value.transport_id
                        });
                        const { environmentCode, klaviyoApiPublicKey, klaviyoApiPrivateKey } = yield getRemoteConfigData(); // added by Bearer Web Developer
                        // the fallowing "Purchase" (Facebook Ads default event)
                        if (environmentCode === "PRODUCTION") {
                            ReactPixel.trackSingle('3587653761499122', 'Purchase'); // For tracking custom events.
                        }
                        // klaviyo event start Order (Payment Authorised, Search Started) -> 'Purchase' 
                        const appState = getParentOfType(self, AppState);
                        // Track a user's behavior - create an event in Klaviyo
                        klaviyo.createEvent({
                            email: appState?.profile?.email,
                            event: "Purchase"
                        });
                        // klaviyo event end
                    }   // Added by Amir Siabi 26 Aug 2024
                    // added by Bearer Web Developer end
                    // added by Bearer Web Developer start
                    const appState = getParentOfType(self, AppState);
                    self.order.searchRider.setTabIndex(appState.selectedOrderIndex);
                    // console.log({ tab: appState.selectedOrderIndex });
                    // added by Bearer Web Developer end
                    self.step = PaymentStepEnum.PaymentSucceed;
                    self.open = false;
                    appState?.fullDiscountAutomatically.setFullDiscountUsedOnce(true); // added by Bearer Web Developer
                    self.order.searchRider.searchRiderByRealtime();
                } else if (result.code === 201) {
                    self.step = PaymentStepEnum.DynamicPassword;
                    self.dynamicPassword = result;
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401
                    });
                } else if (result.code === 402 || result.code === 410 || result.code === 423) {
                    self.step = PaymentStepEnum.RequestIsNotValid;
                    // added by Bearer Web Developer start
                    if (result.code === 423) {
                        if (self.order?.discount?.fullDiscountUser) {
                            self.order?.payment.removeCouponId()
                        }
                    }
                    // added by Bearer Web Developer end
                } else if (result.code === 412) {
                    // added by Bearer Web Developer start
                    if (self.order?.discount?.fullDiscountUser) {
                        self.order?.payment.removeCouponId()
                    }
                    // added by Bearer Web Developer end
                    self.step = PaymentStepEnum.FareUpdated;
                    self.fareUpdateData = result;
                } else if (result.code === 400) {
                    // added by Bearer Web Developer start
                    if (self.order?.discount?.fullDiscountUser) {
                        self.order?.payment.removeCouponId()
                    }
                    // added by Bearer Web Developer end
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Promo Code Rejected
                    // App Event checkDiscountCode || stripePayment = code 400
                    const appState = getParentOfType(self, AppState);
                    logEvent(analytics,"promo_code_rejected",{
                        transport_id: appState.selectedOrder?.transport?.value?.transport_id,
                        original_price: appState.selectedOrder?.transport?.value?.transport_original_price,
                        reason: result?.message
                    });
                    // added by Bearer Web Developer end
                    self.step = PaymentStepEnum.CouponIsNotValid;
                } else if (result.code === 425) {
                    self.step = PaymentStepEnum.SelectedCardHasProblem;
                    self.cardProblem = result;
                } else if (result.code === 413) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error413,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }
            }
            catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;

        }),

        // }))
        // .actions(self => ({
        chooseAnotherPaymentCard: flow(function* () {
            if (!self.order?.transport.value) {
                return false;
            }
            const request: TChooseAnotherPaymentCardRequest = {
                transport_id: self.order.transport.value.transport_id,
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: TChooseAnotherPaymentCardResponse = yield api.chooseAnotherPaymentCard(request)
         
                if (result.code === 200) {
                    self.order.transport.setP();
                    self.setOpen(self.order);
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401
                    });
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402,
                    });
                } else if (result.code === 410) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error410
                    });
                } else if (result.code === 421) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error421,
                    });
                } else if (result.code === 422) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error422,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.login.logout();
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown,
                })
            }
        }),
        customerCancelTransportBeforePayment: flow(function* () {
            if (!self.order?.transport.value) {
                return false;
            }
            const request: TCustomerCancelTransportBeforPaymentRequest = {
                transport_id: self.order.transport.value.transport_id,
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: TCustomerCancelTransportBeforPaymentResponse = yield api.customerCancelTransportBeforePayment(request);
          
                if (result.code === 200) {
                    const appState = getParentOfType(self, AppState);
                    appState.selectedOrder?.transport.removeMyOrder();
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401
                    });
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402,
                    });
                }
                else if (result.code === 410) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error410,
                    });
                }
                else if (result.code === 421) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error421,
                    });
                }
                else if (result.code === 422) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error422,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.login.logout();
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }

            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
        })
    }))

export interface IPaymentState extends Instance<typeof PaymentState> { };



export function CancelReasonLabel(reason: ReasonForCancelRiderEnum | null | undefined) {
    if (reason === ReasonForCancelRiderEnum.DidNotOnTime) {
        return "Bearer didn’t arrive on time";
    } else if (reason === ReasonForCancelRiderEnum.Others) {
        return "Other reason";
    } else if (reason === ReasonForCancelRiderEnum.ChangeMyMind) {
        return "I’ve changed my mind";
    } else if (reason === ReasonForCancelRiderEnum.AddressIsWrong) {
        return "My address is wrong";
    }
    return "unknown";
}


export const SearchRiderState = types
    .model("SearchRiderState", {
        open: types.optional(types.boolean, false),
        step: types.maybeNull(types.enumeration([...Object.values(SearchRiderEnum)])),
        notFoundQty: types.optional(types.integer, 0),
        showCancelButton: types.optional(types.boolean, false),
        SearchRiderResponse: types.maybeNull(types.frozen<TTransportStateResponse>()),
        TabIndex: types.maybeNull(types.integer), // added by Bearer Web Developer
        searchViewTappedCount: types.optional(types.integer, 0), // added by Bearer Web Developer
    })
    .views(self => ({
        get order(): IOrderState | null {
            return getParentOfType(self, OrderState);
        },
    }))
    .actions(self => ({
        clear() {
            self.step = null;
            self.open = false;
        },
        // added by Bearer Web Developer start
        setTabIndex(index: number | null) {
            self.TabIndex = index;
        },
        // added by Bearer Web Developer end
        // added by Bearer Web Developer start
        increaseSearchViewTappedCount() {
            self.searchViewTappedCount = self.searchViewTappedCount + 1;
        },
        // added by Bearer Web Developer end
        setOpen() {
            self.open = true;
            self.step = SearchRiderEnum.Searching;
        },
        setShowCancelButton() {
            self.showCancelButton = true;
        },
        setClose() {
            self.open = false;
        },
        setRiderAcceptTransport() {
            self.step = SearchRiderEnum.RiderFound;
        },
    }))
    .actions(self => ({
        transportCanceledByRider(data: TTransportCanceledByRider) {
            const order = getParentOfType(self, OrderState);
            order.transport.setRiderCancelTransport();
            order.rider.clear(); // added by Bearer Web Developer
            order.setTransportMessage(TransportMessageState.create({
                messageType: TransportStateEnum.RiderCancelledTransport,
                messageText: data.state_description || "unknown",
            }));
        },
        bearerAcceptTransport(data: TTransportAccepted) {
            const order = getParentOfType(self, OrderState);
            // commented by Bearer Web Developer start
            // if (order.searchRider.step !== SearchRiderEnum.Searching) {
            //     return
            // }
            // order.transport.setRiderFound({
            //     bearer_type: data.bearer_type,
            //     destination_address: data.destination_address,
            //     origin_address: data.origin_address,
            //     rider_avatar_url: data.rider_avatar_url,
            //     rider_given_name: data.rider_given_name,
            //     rider_last_name: data.rider_last_name,
            //     cancellation_fee: data.cancellation_fee,
            //     destination_lat: data.destination_lat,
            //     destination_lng: data.destination_lng,
            //     motorbike_make: data.motorbike_make,
            //     motorbike_model: data.motorbike_model,
            //     motorbike_registration_number: data.motorbike_registration_number,
            //     motorbike_registration_state: data.motorbike_registration_state,
            //     order_secret: data.order_secret,
            //     origin_lat: data.origin_lat,
            //     origin_lng: data.origin_lng,
            //     transport_id: data.transport_id,
            //     state_description: data.state_description,
            // });
            // commented by Bearer Web Developer end
            order.setTransportMessage(TransportMessageState.create({
                messageType: TransportStateEnum.RiderOnTheWayToOrigin,
                messageText: data.state_description || "unknown",
            }));
        },
        transportStateA(data: TTransportStateA) {
            const order = getParentOfType(self, OrderState);
            order.transport.setRiderArriveToOrigin();
            const messageType = TransportStateEnum.RiderArrivesToOrigin;
            order.setTransportMessage(TransportMessageState.create({
                messageType,
                messageText: data.state_description || "unknown",
            }))
        },
        transportStateB(data: TTransportStateB) {
            const order = getParentOfType(self, OrderState);
            order.transport.setRiderPickedUpParcel();
            const messageType = TransportStateEnum.RiderPickupParcel;
            order.setTransportMessage(TransportMessageState.create({
                messageType,
                messageText: data.state_description || "unknown",
            }))
        },
        transportStateC(data: TTransportStateC) {
            const order = getParentOfType(self, OrderState);
            order.transport.setRiderArrivedToDestination();
            const messageType = TransportStateEnum.RiderArrivesToDestination;
            order.setTransportMessage(TransportMessageState.create({
                messageType,
                messageText: data.state_description || "unknown",
            }))
        },
        transportStateD(data: TTransportStateD) {
            const order = getParentOfType(self, OrderState);
            order.transport.setRiderGetsPermissionToDropOf();
            const messageType = TransportStateEnum.RiderGetsPermissionToDropOff;
            order.setTransportMessage(TransportMessageState.create({
                messageType,
                messageText: data.state_description || "unknown",

            }))
        },
        bearerCompletedTransport(data: TBearerCompletedTransport) {
            const order = getParentOfType(self, OrderState);
            order.transport.setRiderCompletedTransport();
            order.rider.clear(); // added by Bearer Web Developer
            order.setTransportMessage(TransportMessageState.create({
                messageType: TransportStateEnum.RiderCompletedTransport,
                messageText: data.state_description || "unknown",
            }))

        },
    }))

    .actions(self => ({
        searchRiderByRealtime: flow(function* () {
            self.setOpen();
            if (!self.order?.transport.value) {
                return
            }
            if (!self.open) {
                return;
            }
            if (!self.step) {
                return
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const request: TSearchRiderRequest = {
                    transport_id: self.order.transport.value.transport_id,
                }
                const result: TSearchRiderResponse = yield api.searchRiderByRealtime(request)
      
                if (result.code === 200 || result.code === 201) {
                    self.setShowCancelButton();
                    api.registerForTransportState(self.order)
                } else if (result.code === 401 || result.code === 402 || result.code === 410 || result.code === 411 || result.code === 421) {
                    self.step = SearchRiderEnum.RequestIsNotValid;
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                if (self.step !== SearchRiderEnum.Searching) {
                    return
                }
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
        }),
        searchResponse: (result: TTransportStateResponse) => {
            self.SearchRiderResponse = result;
            if (!self.order) {
                return;
            }
            // added by Bearer Web Developer start
            // Firebase Analytics Event Name Search Area Clicked During the Search
            // when transport be canceled
            if (result.realtime_code === 4) {
                if (result?.code === 422) {
                    logEvent(analytics,"search_view_tapped", {
                        clicks_count_on_search_view: self?.order?.searchRider?.searchViewTappedCount
                    })
                }
            }
            // added by Bearer Web Developer end
            if (result.realtime_code === 3) {
                self.open = false;
                self.step = SearchRiderEnum.RiderFound;
                self.bearerAcceptTransport(result); // added for first showing message of accepted transport by Bearer Web Developer
                self.order.transport.setRiderFound(result);
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name Search Area Clicked During the Search
                // when RiderFound or RidersNotFound it is sent
                if (result?.code === 200) {
                    logEvent(analytics,"search_view_tapped", {
                        clicks_count_on_search_view: self?.order?.searchRider?.searchViewTappedCount
                    })
                }
                // added by Bearer Web Developer end
            } else if (result.realtime_code === 1) {
                if (self.notFoundQty !== 0) {
                    return;
                }
                self.step = SearchRiderEnum.RidersNotFound;
                self.notFoundQty++;
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name No Riders Found 1st / 2nd Search
                // App Event RealTime db = code 201 || 202
                if (result?.code === 201) {
                    logEvent(analytics,"no_rider_found", {
                        transport_id: self?.order?.transport?.value?.transport_id
                    })
                }
                // added by Bearer Web Developer end
            } else if (result.realtime_code === 2) {
                self.step = SearchRiderEnum.RiderNotFoundAfterTwoSearch;
                self.notFoundQty++;
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name No Riders Found 1st / 2nd Search
                // App Event RealTime db = code 201 || 202
                if (result?.code === 202) {
                    logEvent(analytics,"no_rider_found", {
                        transport_id: self?.order?.transport?.value?.transport_id
                    })
                }
                // added by Bearer Web Developer end
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name Search Area Clicked During the Search
                // when RiderFound or RidersNotFound it is sent
                if (result?.code === 202) {
                    logEvent(analytics,"search_view_tapped", {
                        clicks_count_on_search_view: self?.order?.searchRider?.searchViewTappedCount
                    })
                }
                // added by Bearer Web Developer end
            }
            // this condition uncommented by Bearer Web Developer start
             else if (result.realtime_code === 6) {
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: result.message || 'unknown error!', error: MessageEnum.Error423,
                    // added by Bearer Web Developer start
                    extraInformation: {
                        tabIndex: self.order.searchRider.TabIndex || 0,
                    }
                    // added by Bearer Web Developer end
                });
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name Search Area Clicked During the Search
                // when RiderFound or RidersNotFound it is sent
                if (result?.code === 423) {
                    logEvent(analytics,"search_view_tapped", {
                        clicks_count_on_search_view: self?.order?.searchRider?.searchViewTappedCount
                    })
                }
                // added by Bearer Web Developer end
            }
            // this condition uncommented by Bearer Web Developer end
            else if (result.realtime_code === 5) {
                self.transportCanceledByRider(result);
            } else if (result.realtime_code === 7) {
                self.transportStateA(result);
            } else if (result.realtime_code === 8) {
                self.transportStateB(result);
            } else if (result.realtime_code === 9) {
                self.transportStateC(result);
            } else if (result.realtime_code === 10) {
                self.transportStateD(result);
            } else if (result.realtime_code === 11) {
                self.bearerCompletedTransport(result);
            }
        },
        loadTransportState() {

            const transportId = self.order?.transport.value?.transport_id;
            if (!transportId) {
                return;
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const orderState = getParentOfType(self, OrderState);
            api.loadTransportStateFromFirebase(orderState);
            // const appState = getParentOfType(self, AppState);
            // appState.loaded = true;
        },
        CustomerCancelTransportBeforeAccept: flow(function* () {
            if (!self.order?.transport.value) {
                return false;
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const request: TCancelTransportBeforeAcceptRequest = {
                    transport_id: self.order.transport.value.transport_id,
                }
                const result: TCancelTransportBeforeAcceptResponse = yield api.CustomerCancelTransportBeforeAccept(request)
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name Order Cancelled During Search
                // App Event customerCancelTransportBeforeAccept is called
                logEvent(analytics,"order_cancelled_during_search", {
                    transport_id: self.order.transport?.value?.transport_id
                })
                // added by Bearer Web Developer end
                if (result.code === 200) {
                    self.open = false;
                    self.order.clear();
                    setDefaultOrigin(); // added by Bearer Web Developer
                } else if (result.code === 420) {
                    self.step = SearchRiderEnum.CallSupport;
                    // self.callSupport = result;
                    // const appState = getParentOfType(self, AppState);
                    // appState.setErrorMessage({
                    //     message: result.message || 'unknown error!',
                    // });
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401
                    });
                } else if (result.code === 402 || result.code === 410 || result.code === 421
                    || result.code === 422 || result.code === 423) {
                    self.open = false;
                    self.order.clear();
                    setDefaultOrigin(); // added by Bearer Web Developer
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }

            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
        }),
    }))

export interface ISearchRiderState extends Instance<typeof SearchRiderState> { };

export const ReceiveReceiptState = types
    .model("ReceiveReceiptState", {
        selectEmailTransportReceipt: types.maybeNull(types.enumeration([...Object.values(SelectEmailTransportReceiptEnum)])),
        anotherEmail: types.optional(types.string, ""),
        myEmail: types.string,
    })
    .views(self => ({
        get email() {
            if (self.selectEmailTransportReceipt === SelectEmailTransportReceiptEnum.Another) {
                return self.anotherEmail;
            } else {
                return self.myEmail;
            }
        },
    }))
    .actions(self => ({
        setSelectEmailTransportReceipt(value: SelectEmailTransportReceiptEnum) {
            self.selectEmailTransportReceipt = value;
        },
        setAnotherEmail(value: string) {
            self.anotherEmail = value;
        },
    }))

export interface IReceiveReceiptState extends Instance<typeof ReceiveReceiptState> { };


export const TransportHistoryState = types
    .model("TransportHistoryState", {
        cursorTransportId: types.maybeNull(types.string),
        hasMore: types.optional(types.boolean, true),
        transportsHistory: types.array(types.frozen<TTransportsHistoryInfo>()),
    })
    .actions(self => ({
        fetchNextCustomerTransportHistory: flow(function* ({ next }) { // add next property for this function to understand that the data which we want is first data or next data by Bearer Web Developer
            if (!self.hasMore) return;
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();
            // added by Bearer Web Developer start
            if (!next) {
                self.transportsHistory.clear()
                self.cursorTransportId = null;
            }
            // added by Bearer Web Developer end
            try {
                const result: TCustomerTransportHistoryResponse = yield api.customerTransportHistory(self.cursorTransportId);
          
                if (result.code === 200) {
                    const resultArray = Object.keys(result.transports).map(id => result.transports[id]);
                    if (!next || !self.transportsHistory) { // modified (add !next) by Bearer Web Developer
                        self.transportsHistory = cast(resultArray);
                    } else {
                        self.transportsHistory.push(...resultArray);
                    }
                    self.cursorTransportId = result.cursor_transport_id || null;
                    self.hasMore = !!result.cursor_transport_id;
                } else if (result.code === 201) {
                    self.cursorTransportId = null;
                    self.hasMore = false;
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                self.transportsHistory = cast([]);
                self.hasMore = false;
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
        }),

        emailTransportReceipt: flow<boolean, [string, string]>(function* (transport_id: string, email: string) {

            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const request: TEmailTransportReceiptRequest = {
                    transport_id,
                    email,
                }
                const result: TEmailTransportReceiptResponse = yield api.emailTransportReceipt(request);
         
                if (result.code === 200) {
                    // return true;
                    const appState = getParentOfType(self, AppState);
                    appState.setSnackMessage({
                        message: result.message || 'unknown error!',
                    })
                }
                else if (result.code === 201) {
                    const appState = getParentOfType(self, AppState);
                    appState.setSnackMessage({
                        message: result.message || 'unknown error!',
                    })
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401
                    });
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402,
                    });
                } else if (result.code === 410) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error410,
                    });
                } else if (result.code === 421) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error421
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);

                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;
        }),
    }))

export interface ITransportHistoryState extends Instance<typeof TransportHistoryState> { };


export const FavouritePlacesFormState = types
    .model("FavouritePlacesFormState", {
        address: types.maybeNull(types.frozen<AddressType>()),
        placeName: types.optional(types.string, ''),
        placeDetails: types.optional(types.string, ''),
    })
    .actions(self => ({
        setAddress(value: AddressType | null) {
            self.address = value;
        },
        setPlaceName(value: string) {
            self.placeName = value;
        },
        setPlaceDetails(value: string) {
            self.placeDetails = value;
        },
        // added by Bearer Web Developer start
        clearAddressAndPlaceName() {
            self.address = null;
            self.placeName = '';
            self.placeDetails = '';
        }
        // added by Bearer Web Developer end
    }))

export interface IFavouritePlacesFormState extends Instance<typeof FavouritePlacesFormState> { };



export const TransportMessageState = types
    .model("TransportMessageState", {
        messageType: types.maybeNull(types.enumeration([...Object.values(TransportStateEnum)])),
        messageText: types.string,
    })
export interface ITransportMessageState extends Instance<typeof TransportMessageState> { };


export const OrderState = types
    .model("OrderState", {
        id: types.identifier,
        origin: types.optional(OriginState, {}),
        destination: types.optional(DestinationState, {}),
        rider: types.optional(RiderState, {}), // added by Bearer Web Developer
        parcelType: types.optional(ParcelState, {}),
        transport: types.optional(TransportState, {}),
        transportMessage: types.maybeNull(TransportMessageState),
        priceListId: types.maybeNull(types.string),
        transportOptions: types.optional(types.model("TransportOptionState", {
            riding: types.maybeNull(types.frozen<TTransportOption>()),
            cycling: types.maybeNull(types.frozen<TTransportOption>()),
            walking: types.maybeNull(types.frozen<TTransportOption>()),
            selectedOption: types.maybeNull(types.enumeration([...Object.values(TransportTypeEnum)])),
        }), {}),
        discount: types.optional(DiscountState, {}),
        payment: types.optional(PaymentState, {}),
        searchRider: types.optional(SearchRiderState, {}),
        chat: types.optional(ChatState, {}),
        selectedReservedOrder: types.maybeNull(types.frozen<TReservedOrderDetails>()), // added by Bearer Web Developer
    })
    .views(self => ({
        get isTransportEstimated() {
            return !self.transport?.value?.overview_polyline;
        },
        get selectedTransportOption() {
            if (self.transportOptions.selectedOption === TransportTypeEnum.cycling) {
                return self.transportOptions.cycling;
            }
            if (self.transportOptions.selectedOption === TransportTypeEnum.riding) {
                return self.transportOptions.riding;
            }
            if (self.transportOptions.selectedOption === TransportTypeEnum.walking) {
                return self.transportOptions.walking;
            }
            return null;
        },
        get isReadyForPayment(): boolean {
            if (self.transport.value?.transport_id) {
                return true;
            }
            return false;
        },
        get isDisabled() {
            const riderInfo = self.transport.riderInfo;
            if (riderInfo) {
                return true;
            }
            return false;
        },
        get isNotCancelApplicable() {
            if (self.transport.step === TransportStateEnum.RiderArrivesToOrigin ||
                self.transport.step === TransportStateEnum.RiderPickupParcel ||
                self.transport.step === TransportStateEnum.RiderArrivesToDestination ||
                self.transport.step === TransportStateEnum.RiderGetsPermissionToDropOff ||
                self.transport.step === TransportStateEnum.RiderCompletedTransport) {
                return true;
            }
            return false;
        },
    }))

    .actions(self => ({
        // added by Bearer Web Developer start
        setSelectedReservedOrder(value: TReservedOrderDetails | null) {
            self.selectedReservedOrder = value;
        },
        setTransportOption(value: TTransportOption & (TRiding |TCycling | TWalking)) {
            if (value.type === 'riding') {
                self.transportOptions.riding = value;
            } else {
                self.transportOptions.riding = null;
            }
            if (value.type === 'cycling') {
                self.transportOptions.cycling = value;
            } else {
                self.transportOptions.cycling = null;
            }
            if (value.type === 'walking') {
                self.transportOptions.walking = value;
            } else {
                self.transportOptions.walking = null;
            }
        },
        setPriceListId(value: string) {
            self.priceListId = value;
        },
        // added by Bearer Web Developer end
        setTransportMessage(value: ITransportMessageState) {
            self.transportMessage = value;
        },
        selectOption(transportType: TransportTypeEnum | null) {
            self.transportOptions.selectedOption = transportType;
            if (transportType === null) {
                self.transport.clear();
            }
        },
        clear() {
            const appState = getParentOfType(self, AppState)
            self.transportOptions = { cycling: null, riding: null, walking: null, selectedOption: null };
            self.priceListId = null;
            self.discount = DiscountState.create({});
            self.transport = TransportState.create({});
            self.parcelType = ParcelState.create({});
            self.origin = OriginState.create({
                name: !appState.profile.firstName && !appState.profile.lastName ? "" : (appState.profile.firstName + ` ` + appState.profile.lastName), // modified by Bearer Web Developer
                phone: appState.profile.phoneNumber // modified by Bearer Web Developer to have the first 2 digits 
            });
            self.destination = DestinationState.create({});
            self.selectedReservedOrder = null; // added by Bearer Web Developer
        },

        clearTransportData() {
            self.transportOptions = { cycling: null, riding: null, walking: null, selectedOption: null };
            self.priceListId = null;
            self.discount = DiscountState.create({});
            self.transport = TransportState.create({});
            self.parcelType = ParcelState.create({});
        },
        clearTransportOptions() {
            self.transportOptions = { cycling: null, riding: null, walking: null, selectedOption: null };
            self.priceListId = null;
            self.discount = DiscountState.create({});
            self.transport = TransportState.create({});
        },
        clearDiscount() {
            self.discount = DiscountState.create({});
        },

        applyNewSearchRider(new_transport_id: string) {
            self.chat = ChatState.create({});
            self.transport.setNewTransportId(new_transport_id);
            self.searchRider = SearchRiderState.create({
                step: SearchRiderEnum.RiderCancelledRide,
            });
            self.searchRider.searchRiderByRealtime();
        },
    }))
    .actions(self => ({
        fetchPricing: flow<boolean, []>(function* () {
            if (!self.parcelType.type) {
                return false;
            }

            const parcel_description = self.parcelType.type.parcel_description;
            const parcel_min_weight = self.parcelType.type.parcel_min_weight;
            const parcel_max_weight = self.parcelType.type.parcel_max_weight;
            const parcel_type = self.parcelType.type.parcel_type;
            const walking = self.parcelType.type.vehicle_type.walking;
            const riding = self.parcelType.type.vehicle_type.riding;
            const cycling = self.parcelType.type.vehicle_type.cycling;
            const parcel_img_url = self.parcelType.type.parcel_img_url; // added by Bearer Web Developer
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: TPricingResponse = yield api.pricing({
                    origin: {
                        lat: Number(self.origin.address?.location.lat),
                        lng: Number(self.origin.address?.location.lng),
                    },
                    destination: {
                        lat: Number(self.destination.address?.location.lat),
                        lng: Number(self.destination.address?.location.lng),
                    },
                    parcel_description,
                    parcel_min_weight,
                    parcel_max_weight,
                    parcel_img_url, // added by Bearer Web Developer
                    parcel_type,
                    vehicle_type: {
                        walking,
                        riding,
                        cycling,
                    },
                });
     
                if (result.code === 200) {

                    self.priceListId = result.price_list_id;
                    if (result.riding) {
                        self.transportOptions.riding = result.riding;
                    } else {
                        self.transportOptions.riding = null;
                    }
                    if (result.cycling) {
                        self.transportOptions.cycling = result.cycling;
                    } else {
                        self.transportOptions.cycling = null;
                    }
                    if (result.walking) {
                        self.transportOptions.walking = result.walking;
                    } else {
                        self.transportOptions.walking = null;
                    }
                    return true;
                } else if (result.code === 201) {
                    const appState = getParentOfType(self, AppState);
                    appState.setSnackMessage({
                        message: result.message || 'unknown error!'
                    });
                } else if (result.code === 403) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error403,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }

            return false;
        }),
        fetchTransport: flow<boolean, []>(function* () {

            const o = self.selectedTransportOption;
            if (!o) {
                //TODO error
                return false;
            }
            const price_list_id = self.priceListId;
            if (!price_list_id) {
                //TODO error
                return false;
            }
            if (!self.origin) {
                return false;
            }
            if (!self.destination) {
                return false;
            }
            const origin_phone_number = "+61" + self.origin.phone?.slice(1); // modify by Bearer Web Developer (change 0 to +61);
            const sender_name = self.origin.name;
            const recipient_name = self.destination.name;
            const recipient_phone_number = "+61" + self.destination.phone?.slice(1); // modify by Bearer Web Developer (change 0 to +61)
            const dropoff_verification_method = self.destination.approvalMethods;

            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const request = {
                    price_list_id,
                    selected_mode_of_transport: o.type,
                    origin_details: self.origin.moreDetails,
                    origin_phone_number,
                    sender_name,
                    destination_details: self.destination.moreDetails,
                    recipient_name,
                    recipient_phone_number,
                    dropoff_verification_method,
                };
                const result: TTransportResponse = yield api.transport(request);
                self.transport.clear();
          
                if (result.code === 200) {
                    const { code, status, ...value } = result;
                    self.transport.value = value;
                    saveSelectedTransportId(self.transport.value.transport_id);
                    return true;
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }

            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    // it is better to use function to format a better message
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                });
            }
            return false;
        }),

        fetchDiscount: flow<boolean, []>(function* () {
            const discount_token = self.discount.discountCode;
            if (!self.transport.value) {
                return false
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const request: TDiscountRequest =
                {
                    discount_token,
                    transport_id: self.transport.value?.transport_id,
                    user_type: "Business",
                };
                const result: TDiscountResponse = yield api.discount(request)
                // added by Bearer Web Developer start
                // Firebase Analytics Event Name Promo Code Entered
                // App Event checkDiscountCode is called
                logEvent(analytics,"promo_code_entered",{
                    transport_id: self.transport?.value?.transport_id,
                    original_price: self.transport?.value?.transport_original_price
                });
                // added by Bearer Web Developer end
                if (result.code === 200) {
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Promo Code Accepted
                    // App Event checkDiscountCode = code 200
                    logEvent(analytics,"promo_code_accepted",{
                        transport_id: self.transport?.value?.transport_id,
                        original_price: self.transport?.value?.transport_original_price?.toString(),
                        receivable_price: result?.transport_receivable_price?.toString()
                    });
                    // added by Bearer Web Developer end
                    self.discount.value = result;
                    // console.log({discountvalue : result}) // added by Bearer Web Developer
                    // added by Bearer Web Developer start (for 100% Discount – FREE Code)
                    self.discount?.setFullDiscountUser(result?.transport_receivable_price === 0);
                    // added by Bearer Web Developer end (for 100% Discount – FREE Code)
                    return true;
                } else if (result.code === 400 || result.code === 401) {
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Promo Code Rejected
                    // App Event checkDiscountCode || stripePayment = code 400
                    if (result.code === 400) {
                        logEvent(analytics,"promo_code_rejected",{
                            transport_id: self.transport?.value?.transport_id,
                            original_price: self.transport?.value?.transport_original_price,
                            reason: result?.message
                        });
                    }
                    // added by Bearer Web Developer end
                    throw result.message
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402
                    });
                } else if (result.code === 410) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error410,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                throw err;
            }
            return false;
        }),

        transportRequestAgain: flow<boolean, []>(function* () {
            if (!self.transport.value) {
                return false;
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const request: TTransportRequestAgainRequest = {
                transport_id: self.transport.value.transport_id,
            }
            const result: TTransportRequestAgainResponse = yield api.transportRequestAgain(request);
            try {
            
                if (result.code === 200) {
                    self.applyNewSearchRider(result.new_transport_id);
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401
                    });
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402,
                    });
                } else if (result.code === 410) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error410,
                    });
                } else if (result.code === 421) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error421
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }
                return true;
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;
        }),

        customerCancelTransportAfterCancelByRider: flow<boolean, []>(function* () {
            if (!self.transport.value) {
                return false;
            }
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const request: TCustomerCancelTransportAfterCancelByRiderRequest = {
                transport_id: self.transport.value.transport_id,
            }
            const result: TCustomerCancelTransportAfterCancelByRiderResponse = yield api.customerCancelTransportAfterCancelByRider(request);
            try {
           
                if (result.code === 200) {
                    const appState = getParentOfType(self, AppState);
                    appState.selectedOrder?.transport.removeMyOrder();
                    appState.setSnackMessage({
                        message: result.message || 'unknown error!'
                    });
                } else if (result.code === 401) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401
                    });
                } else if (result.code === 402) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error402,
                    });
                } else if (result.code === 410) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error410,
                    });
                } else if (result.code === 421) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error421,
                    });
                } else if (result.code === 422) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error422,
                    });
                } else if (result.code === 500) {
                    const appState = getParentOfType(self, AppState);
                    appState.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError
                    });
                }
                return true;
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;
        })
    }))
export interface IOrderState extends Instance<typeof OrderState> { };


export const LoginState = types
    .model("LoginState", {
        email: types.optional(types.string, ""),
        password: types.optional(types.string, ""),
        uid: types.maybeNull(types.string),
        loggedin: types.optional(types.boolean, false),
        token: types.optional(types.string, ""),
        newPassword: types.optional(types.string, ""), // (for change password popup) added by Bearer Web Developer

    })
    .actions(self => ({
        loadState: async () => {
            
            const appState = getParentOfType(self, AppState);
            const r1: boolean = await appState.checkCustomerLoginPermission();
            if (r1) {
                const r2: boolean = await appState.updateCustomerFcmToken();
                // klaviyo profile start Login (Existing user) -> (Location == G.Analytics Event)
                await klaviyo.createOrUpdateProfile({
                    type: 'profile',
                    attributes: {
                        email: appState?.profile?.email,
                        properties: {
                            external_id: appState?.profile?.uid?.slice(-7) || '',
                            last_open: getToday(), // Today FORMAT YYYY-MM-DD
                            platform_type: "web",
                        }
                    },
                });
                // klaviyo profile end
                if (r2) {
                    const r3: boolean =  await appState.getCustomerAppState(); // modified by Bearer Web Developer
                    // added by Bearer Web Developer start
                    if (r3) {
                        await appState.profile.handleVersionControl();
                    }
                    // added by Bearer Web Developer end
                }
            }
            // appState.profile.handleVersionControl(); // commented by Bearer Web Developer

        },
    }))
    .actions(self => ({
        setEmail(value: string) {
            self.email = value;
        },
        setPassword(value: string) {
            self.password = value;
        },
        setToken(value: string) {
            self.token = value;
        },
        // (for change password popup) added by Bearer Web Developer start
        setNewPasword(value: string) {
            self.newPassword = value;
        },
        // (for change password popup) added by Bearer Web Developer end
        login: flow<boolean, []>(function* () {

            self.loggedin = false;
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: TLoginResponse = yield api.login(self.email, self.password);
                // console.log({login: result}); // added by Bearer Web Developer 
                self.loggedin = true;
                self.uid = result?.uid; // modified by Bearer Web Developer (safenull)
                yield self.loadState();
                api.registerForMessages();
                const appState = getParentOfType(self, AppState);
                appState.refetchPricing();
                return true;
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                self.loggedin = false;
                self.uid = null;
                throw err;
            }
            // return false;
        }),

        fetchResetPassword: flow<boolean, []>(function* () {
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                yield api.resetPassword(self.email);
            } catch (err: any) {
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;
        }),

        // (for change password popup) added by Bearer Web Developer start
        fetchChangePassword: flow<{ result: boolean | undefined, error: any, update: string }, []>(function* () {
            const api = getEnv<EnvType>(self).api;
            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();
            try {
                const auth = getAuth()
                const user: User | null = auth.currentUser;
                const credential = EmailAuthProvider.credential(auth?.currentUser?.email as string, self.password);
                yield reauthenticateWithCredential(user as User, credential);
                const result = yield updatePassword(user as User, self.newPassword);
                // Update successful.
                return { result, error: '', update: "successful" };
            } catch (error) {
                // An error ocurred
                logConsole({ error })
                return { result: false, error, update: "An error ocurred" };
            }
        }),
        // (for change password popup) added by Bearer Web Developer end
        logout: flow<boolean, []>(function* () {
            const api = getEnv<EnvType>(self).api;
            try {
                // api.unregisterForMessages(); //TODO
                yield api.logout();
                self.loggedin = false;
                self.uid = null;
                // added by Bearer Web Developer start
                // Firebase Analytics Set user ID
                setUserId(analytics, "Anonymous");
                // added by Bearer Web Developer end
                // added by Bearer Web Developer start
                const appState = getParentOfType(self, AppState);
                // appState.selectedOrder?.origin.clear();
                // appState.selectedOrder?.destination.clear();
                // appState.selectedOrder?.parcelType.clear();
                // appState.selectedOrder?.origin.setEditing(true);
                // appState.selectedOrder?.destination.setEditing(false);
                // appState.selectedOrder?.parcelType.setEditing(false);
                appState.orders.map(order=> {
                    order?.origin.clear();
                    order?.destination.clear();
                    order?.parcelType.clear();
                    order?.origin.setEditing(true);
                    order?.destination.setEditing(false);
                    order?.parcelType.setEditing(false);
                    order?.transport?.setEditing(false);
                    order?.transport.clear();
                    order?.searchRider?.clear();
                    order?.setSelectedReservedOrder(null);
                });
                // added by Bearer Web Developer end
            } catch (err: any) {
                const appState = getParentOfType(self, AppState);
                appState.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                })
            }
            return false;
        }),
    }))

export interface ILoginState extends Instance<typeof LoginState> { };

export interface IErrorMessage {
    // We can have more data here to be used on snackbar, like type (error/warning/info) or some other stuff like timeout ...
    message: string;
    error: MessageEnum;
    // added by Bearer Web Developer start
    extraInformation?: {
        tabIndex?: number,
    }
    // added by Bearer Web Developer end
}

export interface ISnackMessage {
    // We can have more data here to be used on snackbar, like type (error/warning/info) or some other stuff like timeout ...
    message: string;
    handleClick?: () => void | Promise<void>; // Promise<void> added by Bearer Web Developer
    buttonMessage?: string; // added by Bearer Web Developer
    hasSopportLink?: boolean; // added by Bearer Web Developer
    hasLoadingForHandleClick?: boolean; // added by Bearer Web Developer
}

export const SimpleMessageState = types
    .model("SimpleMessageState", {
        message: types.string,
    })
export interface ISimpleMessageState extends Instance<typeof SimpleMessageState> { };

export const AppState = types
    .model("AppState", {
        login: types.optional(LoginState, {}),
        loaded: types.optional(types.boolean, false),
        serverSync: types.optional(types.boolean, false),
        errorMessage: types.maybeNull(types.frozen<IErrorMessage>()),
        snackMessage: types.maybeNull(types.frozen<ISnackMessage>()),
        simpleMessages: types.array(SimpleMessageState),
        orders: types.array(OrderState),
        parcelTypes: types.array(types.frozen<ParcelType>()),
        transportTypes: types.array(types.frozen<TransportType>()),
        selectedOrderIndex: types.optional(types.number, -1),
        profile: types.optional(ProfileState, {}),
        favouritePlaces: types.optional(FavouritePlacesState, {}),
        transportHistory: types.optional(TransportHistoryState, {}),
        serverChecked: types.optional(types.boolean, false),
        noCloudFunctions: types.maybeNull(types.frozen<NoCloudFunctionData>()),
        noWebApp: types.maybeNull(types.frozen<NoWebAppData>()),
        webAppRemoteConfigParameters: types.maybeNull(types.frozen<WebAppRemoteConfigParameters>()),
        checkingDevice: types.optional(types.boolean, false),
        userStatus: types.optional(UserState, {}), // added by Bearer Web Developer
        environmentCode: types.optional(types.string, ''), // added by Bearer Web Developer
        addToHomeScreen: types.maybeNull(types.frozen<IAddToHomeScreen>()), // added by Bearer Web Developer
        ReservedOrders: types.optional(ReservedOrdersState, {}), // added by Bearer Web Developer
        ApiIntegration: types.optional(ApiIntegrationState, {}), // added by Bearer Web Developer
        fullDiscountAutomatically : types.optional(FullDiscountAutomaticallyState, {}), // added by Bearer Web Developer
        freeOrder : types.optional(FreeOrderState, {}), // added by Bearer Web Developer
    })
    .views(self => {
        return {
            get selectedOrder() {
                if (self.selectedOrderIndex === -1) return null;
                return self.orders[self.selectedOrderIndex];
            },
        }
    })
    .actions(self => ({
        // added by Bearer Web Developer start
        setCheckingDevice(boolean: boolean) {
            self.checkingDevice = boolean;
        },
        // added by Bearer Web Developer end
        fetchParcelTypes: flow(function* () {
            // modify parcelTypes to update every time by Bearer Web Developer
            self.parcelTypes.clear();
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            try {
                const result: ParcelType[] = yield api.fetchParcelTypes();

                self.parcelTypes.push(...result);
            } catch (err) {
            }
        }),
        addOrder: flow(function* () {
            yield self.profile.fetchProfile();
            self.orders.push({
                id: uuid_v4(),
                origin: {
                    name: !self.profile.firstName && !self.profile.lastName ? "" : (self.profile.firstName + ` ` + self.profile.lastName), // modified by Bearer Web Developer,
                    phone: self.profile.phoneNumber,
                },
            });
        }),
        removeOrder(order: IOrderState) {
            self.orders.remove(order);
        },
        setErrorMessage(value?: IErrorMessage) {
            self.errorMessage = value || null;
        },
        setSnackMessage(value?: ISnackMessage) {
            self.snackMessage = value || null;
        },
        // added by Bearer Web Developer start
        setAddToHomeScreen(value?: IAddToHomeScreen) {
            self.addToHomeScreen = value || null;
        },
        // added by Bearer Web Developer end
        selectOrder(value: number) {
            if (value >= self.orders.length || value < -1) {
                error("value out of range"); // throw new Error modified by Bearer Web Developer
            }
            self.selectedOrderIndex = value;

            if (self.selectedOrder?.transport.value?.transport_id) {
                saveSelectedTransportId(self.selectedOrder.transport.value.transport_id);
            }
        },
        removeLastMessage() {
            self.simpleMessages.splice(0, 1);
        },

    }))
    .actions(self => ({
        getCustomerAppState: flow(function* () {
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            yield self.fetchParcelTypes();
            const request: TGetCustomerAppStateRequest = {
                platform_type: 'web',
            }
            const result: TGetCustomerAppStateResponse = yield api.getCustomerAppState(request);
            try {
                // console.log({getCustomerAppState: result}) // added by Bearer Web Developer
                if (result.code === 200) {
                    const orders = extractOrders(result?.transports, self.parcelTypes); // modified by Bearer Web Developer
                    const oldOrders = getSnapshot(self.orders);
                    self.orders.clear();
                    self.selectOrder(-1)
                    self.orders.push(...orders);
                    // console.log({orderLimits:self.profile.orderLimits!})  // added by Bearer Web Developer
                    // in the fallowing line orderLimits modified instead of 5 by Bearer Web Developer
                    if (self.orders.length < (self.profile.orderLimits! || 5)) {
                        const toKeepOrders = oldOrders.filter(
                            o => {
                                if (!(o.origin.address?.location.lat &&
                                    o.destination.address?.location.lat &&
                                    o.parcelType.type?.parcel_type)) return false;
                                const i = orders.findIndex(no => no.transport.value?.transport_id === o.transport.value?.transport_id);
                                if (i !== -1) return false;
                                return true;
                            }
                        );
                        // in the fallowing line orderLimits modified instead of 5 by Bearer Web Developer
                        for (let i = 0; i < Math.min(toKeepOrders.length, (self.profile.orderLimits! || 5) - self.orders.length); i++) {
                            self.orders.push(toKeepOrders[toKeepOrders.length - 1 - i]);
                        }
                    }

                    const messages = extractMessages(result?.transports, self.parcelTypes); // modified by Bearer Web Developer
                    self.simpleMessages.clear();
                    self.simpleMessages.push(...messages);

                    const selectedTransportId = getSelectedTransportId();
                    const selectedOrderIndex = self.orders.findIndex(o => o.transport.value?.transport_id === selectedTransportId);
                    if (self.orders.length === 0) {
                        yield self.addOrder();
                        self.selectOrder(0)
                    } else {
                        self.selectOrder(selectedOrderIndex === -1 ? 0 : selectedOrderIndex)
                    }
                    // added by Bearer Web Developer start
                    if (result?.state === "No Active Transport") return true; 
                    if (result?.transports) {
                        const AllshowAlert =  Object.keys(result?.transports).filter((id)=> result?.transports?.[id]?.state === "Show Alert");
                        const isAllshowAlert = AllshowAlert?.length === Object.keys(result?.transports)?.length;
                        if (isAllshowAlert) return true; 
                    }
                    // added by Bearer Web Developer end
                } else if (result.code === 401) {
                    self.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 500) {
                    self.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.ServerError,
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                self.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown,
                });
            }
            return false; // added by Bearer Web Developer
        }),

    }))
    .actions(self => ({
        updateCustomerFcmToken: flow(function* () {
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const result: TUpdateCustomerFcmTokenResponse = yield api.updateCustomerFcmToken();
            try {
                // console.log({updateCustomerFcmToken: result}) // added by Bearer Web Developer
                if (result?.code === 200) {
                    return true;
                } else if (result.code === 404) {
                    self.checkingDevice = true;
                } else if (result.code === 403) {
                    self.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error403,
                    });
                } else if (result.code === 401) {
                    self.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error401,
                    });
                } else if (result.code === 500) {
                    self.setErrorMessage({
                        message: result.message || 'unknown error!',
                        error: MessageEnum.ServerError,
                    });
                }
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                self.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                });
            }
            return false;
        }),
    }))

    .actions(self => ({
        checkCustomerLoginPermission: flow(function* () {
            const api = getEnv<EnvType>(self).api;

            ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
            api.callApiWithAppCheck();

            const result: TCheckCustomerLoginPermissionResponse = yield api.checkCustomerLoginPermission();
            // console.log({checkCustomerLoginPermissionresult:result}); // added by Bearer Web Developer
            try {
                // console.log({checkCustomerLoginPermission: result}) // added by Bearer Web Developer
                if (result.code === 200) {
                    self.profile.setData(result);
                    // added by Bearer Web Developer start
                    if (self?.login?.uid) {
                        api.userStatus(self?.login?.uid);
                    }
                    // added by Bearer Web Developer end
                    // added by Bearer Web Developer start
                    // Firebase Analytics Event Name Login (Existing User)
                    // App Event checkCustomerLoginPermission = code 200
                    logEvent(analytics,"existing_user_login", {
                        method: "Ggl.F.B. Email",
                        user_type: "Business"
                    })
                    // klaviyo profile start Login (Existing user) -> (Location == G.Analytics Event)
                    // yield klaviyo.createOrUpdateProfile({
                    //     type: 'profile',
                    //     attributes: {
                    //         email: self?.profile?.email,
                    //         properties: {
                    //             external_id: self?.profile?.uid?.slice(-7) || '',
                    //             last_open: getToday(), // Today FORMAT YYYY-MM-DD
                    //             platform_type: "web",
                    //         }
                    //     },
                    // });
                    // klaviyo profile end
                    // the fallowing "Login" (Facebook Ads default event)
                    const { environmentCode } = yield getRemoteConfigData();
                    if (environmentCode === "PRODUCTION") {
                        ReactPixel.trackSingleCustom('3587653761499122', 'Login'); // For tracking custom events.
                    }
                    // added by Bearer Web Developer end
                    // added by Bearer Web Developer start (ApiIntegration)
                    self.ApiIntegration.is_api_access_allowed = result?.is_api_access_allowed;
                    self.ApiIntegration.api_access_not_allowed_reason = result?.api_access_not_allowed_reason;
                    self.ApiIntegration.is_api_access_enabled_by_user = result?.is_api_access_enabled_by_user;
                    self.ApiIntegration.switchIntegration = result?.is_api_access_enabled_by_user
                    self.ApiIntegration?.setApiIntegrationData({
                        allowed_intermediate_api_providers: [result?.allowed_intermediate_api_providers[0]], // First item cannot be removed
                        allowed_intermediate_api_platfroms: [result?.allowed_intermediate_api_platfroms[0]], // First item cannot be removed
                        intermediate_api_providers:[
                            ...result?.allowed_intermediate_api_providers.filter(((_,i)=>(i > 0))) // All items except the first item can be removed
                        ],
                        intermediate_api_platfroms:[
                            ...result?.allowed_intermediate_api_platfroms.filter(((_,i)=>(i > 0))) // All items except the first item can be removed
                        ],
                        api_key: result?.api_key
                    })
                    // added by Bearer Web Developer end (ApiIntegration)
                    // added by Bearer Web Developer start (for 100% Discount – FREE Code)
                    self.fullDiscountAutomatically?.setFullDiscount(result?.is_full_discount);
                    self.fullDiscountAutomatically?.setCouponId(result?.coupon_id);
                    // added by Bearer Web Developer end (for 100% Discount – FREE Code)
                    return true;
                } else if (result.code === 401) {
                    // the fallowing added by Bearer Web Developer start
                    self.setSnackMessage({
                        message: result.message || 'unknown error!',
                        handleClick: () => {
                            self.login.logout();
                            window.location.reload();
                        }
                    });
                    // the fallowing added by Bearer Web Developer end
                } else if (result.code === 406) {
                    // console.log({agreementNeeded : self.profile.agreementNeeded}) // added by Bearer Web Developer
                    self.login.logout();
                    self.setErrorMessage({
                        message: result.message || 'unknown error!', error: MessageEnum.Error406,
                    });
                } else if (result.code === 408 || result.code === 500 || result.code === 501 || result.code === 502) {
                    self.setErrorMessage({
                        message: result.message || 'unknown error!',
                        error: MessageEnum.ServerError,
                    });
                }
                return false;
            } catch (err: any) {
                error(err); // modify by Bearer Web Developer
                self.setErrorMessage({
                    message: err?.message || 'unknown error!',
                    error: MessageEnum.Unknown
                });
            }
            return false;
        })
    }))

    .actions(self => {
        return {
            refetchPricing: () => {

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                const api = getEnv<EnvType>(self).api;
                api.callApiWithAppCheck();

                self.orders.forEach(order => {
                    if (order.isTransportEstimated) {
                        order.fetchPricing();
                    }
                })
            },
            getWebAppRemoteConfigParameters: flow(function* () {
                const api = getEnv<EnvType>(self).api;

                ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                api.callApiWithAppCheck();

                yield api.fetchRemoteConfigurations();
                self.webAppRemoteConfigParameters = api.bearerWebAppRemoteConfigParameters();
                self.environmentCode = api.getEnvironmentCode(); // added by Bearer Web Developer
            }),
            checkServer: flow(function* () {
                const api = getEnv<EnvType>(self).api;
                api.callApiWithAppCheck();
                yield api.fetchRemoteConfigurations();
                self.noCloudFunctions = api.bearerCloudFunctionIsRunning();
                if (self.noCloudFunctions) return;
                self.noWebApp = api.bearerWebAppIsRunning();
                if (self.noWebApp) return;
                self.serverChecked = true;
            }),
            handleLoaded: flow(function* () {
                try {

                    ///// To solve AppCheck - WebApp Issue on Loading App State and Tabs if any
                    const api = getEnv<EnvType>(self).api;
                    api.callApiWithAppCheck();
                    // added by Bearer Web Developer start
                    const supported = yield isSupported();
                    logConsole({supported});
                    if (supported) yield requestPermission(); // added by Bearer Web Developer
                    // added by Bearer Web Developer end
                    if (self.login.loggedin) {
                        yield self.login.loadState();
                    }
                    self.serverSync = true;

                } catch (err) {

                }
            }),
        }
    })
    .actions(self => ({
        finishOrderLife(orderId: string) {
            const orderIndex = self.orders.findIndex(o => o.id === orderId);
            if (orderIndex !== -1) {
                self.orders.splice(orderIndex, 1);
                self.addOrder();
            }
        },
    }))


export const AppStoreContext = React.createContext<IAppState | null>(null);
export const AppStateProvider: React.FC = ({ children }) => {
    const store = useLocalObservable(createStore);
    React.useEffect(() => {
        if (!store.loaded) {
            BearerApi.onLoad(() => {
                if (!store.serverSync) {

                    store.handleLoaded();
                }
            })
        } else {
            store.handleLoaded();
        }
    }, [store])
    return <AppStoreContext.Provider value={store}>{children}</AppStoreContext.Provider>
}

export const useAppState = () => {
    const store = React.useContext(AppStoreContext);
    if (!store) {
        error('useAppStore must be used within StoreProvider'); // throw new Error modified by Bearer Web Developer
    }
    return store!; // used safe null by Bearer Web Developer
}
export type EnvType = {
    api: TBearerApi,
}

export function createStore() {
    const injection: EnvType = {
        api: BearerApi,
    };
    let store;
    try {
        const snapshot = loadStore();
        snapshot.serverChecked = false;
        snapshot.serverSync = false;
        snapshot.loaded = BearerApi.isLoaded();
        store = AppState.create(snapshot, injection);

    } catch (err) {
        log.warn('could not create the state,fallback to empty one.');
        log.warn(err);
        store = AppState.create(emptyStore(), injection);
    }
    onSnapshot(store, saveStore);
    injection.api.registerState(store);
    return store;
}
export function createTestStore(snapshot: IAppStateSnapshot): IAppState {
    const injection: EnvType = {
        api: BearerApi,
    };
    const store = AppState.create(snapshot, injection);
    return store;
}
export const SNAPSHOT_KEY = 'app-store';
function loadStore(): IAppStateSnapshot {
    const snapshot = localStorage.getItem(SNAPSHOT_KEY)
    try {
        return snapshot ? JSON.parse(snapshot) : emptyStore();
    } catch (err) {
        log.warn('could not create the state,fallback to empty one.');
        log.warn(err);
        return emptyStore();
    }
}

function saveStore(snapshot: IAppStateSnapshot) {
    //    localStorage.setItem(SNAPSHOT_KEY, JSON.stringify(snapshot));
    localStorage.setItem(SNAPSHOT_KEY, JSON.stringify({
        login: snapshot.login,
    }));
}
function emptyStore(): IAppStateSnapshot {
    return {};
}
export interface IAppState extends Instance<typeof AppState> { };
export interface IAppStateSnapshot extends SnapshotIn<typeof AppState> { };



const SELECTED_TRANSPORT_KEY = "Bearer_Selected_Transport";
export function saveSelectedTransportId(transport_id: string) { // export added by Bearer Web Developer
    localStorage.setItem(SELECTED_TRANSPORT_KEY, transport_id);
}

function getSelectedTransportId() {
    return localStorage.getItem(SELECTED_TRANSPORT_KEY);
}
