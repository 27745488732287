import React, { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, ListItem, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import StipePayment from "./StripePayment";
import { IPaymentState, PaymentStepEnum, useAppState } from "../../states/app-state";
import { analytics, TCardInformation } from "../../api/bearer-api";
import AllowCancellationTransportBeforePayment from "../order/CancelTransportBeforePayment";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import getImageOfCart from "./ImageOfCard";
import styles from './PaymentPopup.module.scss'; // added by Bearer Web Developer
import { logEvent } from "firebase/analytics"; // added by Bearer Web Developer
import klaviyo from "../../states/klaviyo"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer


const PaymentPopup: React.FC = observer(() => {
    const appState = useAppState();
    const { step, open, order } = appState.selectedOrder!.payment;
    const realPrice = order?.selectedTransportOption?.price;
    const discountedPrice = order?.discount.value?.transport_receivable_price;
    // modified by Bearer Web Developer start
    return (
        <>
            <Dialog open={open} PaperProps={{ className: styles.root,style: { borderRadius: "10px" }  }}>
                <div className={styles.payment}>
                    <div className={styles.header}>
                        <h3>Payment Options</h3>
                        {discountedPrice &&
                            <div className={styles.prices}>
                                <h6 className={styles.real_price}>A$ {realPrice}</h6>
                                <h6 className={styles.discounted_price}>A$ {discountedPrice}</h6>
                            </div>
                        }
                        {!discountedPrice &&
                            <h6 className={styles.real_price_no_discounted}>A$ {realPrice}</h6>
                        }

                    </div>
                    {step === PaymentStepEnum.SelectMethod && <ShowCards />}
                    {step === PaymentStepEnum.RequestIsNotValid && <RequestIsNotValidForPayment payment={appState.selectedOrder!.payment} />}
                    {step === PaymentStepEnum.FareUpdated && <FareUpdated payment={appState.selectedOrder!.payment} />}
                    {step === PaymentStepEnum.CouponIsNotValid && <CouponIsNotValid payment={appState.selectedOrder!.payment} />}
                    {step === PaymentStepEnum.SelectedCardHasProblem && <SelectedCardHasProblem payment={appState.selectedOrder!.payment} />}
                </div>
            </Dialog >
            {step === PaymentStepEnum.DynamicPassword && <DynamicPassword payment={appState.selectedOrder!.payment} />}
        </>
    );
    // modified by Bearer Web Developer end
});
export default PaymentPopup;

const PaymentActions: React.FC = observer(() => {

    const appState = useAppState();
    const [loading, setLoading] = React.useState(false);
    const handleConfirmPayment = async () => {
        setLoading(true);
        await appState.selectedOrder?.payment.paymentAuthorization();
        setLoading(false);
    }
    const { selectedCard } = appState.selectedOrder!.payment;
    const handleClosePaymentPopup = () => {
        appState.selectedOrder?.payment.setClose();
    }
    const [cancelPopupOpen, setCancelPopupOpen] = React.useState(false);
    // modified by Bearer Web Developer start
    const handleCancel = async () => { // create async this function (add async) by Bearer Web Developer
        // added by Bearer Web Developer start
        // Firebase Analytics Event Name Payment View Closed
        // App Event Payment popup closed by Cancel Button
        logEvent(analytics,"left_payment_view",{
            transport_id: appState?.selectedOrder?.transport?.value?.transport_id
        });
        // klaviyo event start Payment View Closed -> 'LeftPaymentView' 
        await klaviyo.createEvent({
            email: appState?.profile?.email,
            event: "Left Payment View"
        });
        // klaviyo event end
        // added by Bearer Web Developer end
        const shouldInformServerOnCancel = appState.selectedOrder?.transport?.shouldInformServerOnCancel;
        if (!shouldInformServerOnCancel) {
            handleClosePaymentPopup();
        } else {
            setCancelPopupOpen(true);
        }
    }
    // modified by Bearer Web Developer end
    const handleCloseAlert = () => {
        setCancelPopupOpen(false)
    }
    // modified by Bearer Web Developer start
    return (
        <>
            <AllowCancellationTransportBeforePayment open={cancelPopupOpen} handleClose={handleCloseAlert} />
            <DialogActions className={styles.payment_actions}>
                <Button
                    fullWidth
                    onClick={handleCancel}
                    className={styles.cancel}
                    disableRipple={true}
                >
                    Cancel
                </Button>
                <Button
                    fullWidth
                    onClick={handleConfirmPayment}
                    disabled={!selectedCard}
                    className={styles.pay}
                    disableRipple={true}
                >
                    Pay
                </Button>
                {loading && <DisabledClickLoading />}
            </DialogActions>
        </>
    )
    // modified by Bearer Web Developer end
});
const ShowCards: React.FC = observer(() => {
    const appState = useAppState();
    const { cardOptions } = appState.selectedOrder!.payment;
    React.useEffect(() => {
        appState.selectedOrder?.payment.prepareCardOptions(false);
    }, [appState]);
    // added by Bearer Web Developer start
    // klaviyo event start Payment View Opened -> 'CheckoutInitiated' 
    useEffect(() => {
        if (cardOptions) {
            // Track a user's behavior - create an event in Klaviyo
            klaviyo.createEvent({
                email: appState?.profile?.email,
                event: "Checkout Initiated"
            });
        }
    }, [cardOptions]);
    // klaviyo event end
    // added by Bearer Web Developer end
    // modified by Bearer Web Developer start
    return (
        <div className={styles.show_cards}>
            <Accordion className={styles.accordion} elevation={0}>
                <AccordionSummary className={styles.accordion_summary}
                    classes={{
                        root: styles.accordion_summary_root,
                        content: styles.accordion_summary_content,
                        expanded: styles.accordion_summary_expanded,
                        expandIcon: styles.accordion_summary_expand_icon,
                    }}
                    IconButtonProps={{ disableRipple: true }}
                    expandIcon={
                        <Button className={styles.expand_icon}>
                            <h6>Payment method</h6>
                            <img src="/expand-address.png" alt="" />
                        </Button>
                    }>
                    <p>Please choose a payment option.</p>
                </AccordionSummary>
                <AccordionDetails classes={{ root: styles.accordion_details }}>
                    <StipePayment />
                </AccordionDetails>
            </Accordion>
            <div className={styles.card_options}>
                {cardOptions?.map((cardOption, i) => <ShowCard key={cardOption.id} cardInformation={cardOption} withDivider={i !== 0} />)}
            </div>
            {!cardOptions &&
                <div className={styles.circular_progress}>
                    <CircularProgress />
                </div>
            }
            <PaymentActions />
        </div>
    )
    // modified by Bearer Web Developer end
})

const ShowCard: React.FC<{ cardInformation: TCardInformation, withDivider: boolean }> = observer((({ cardInformation }) => {
    const appState = useAppState();
    const [deleting, setDeleting] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleConfirmRemoveCard = async () => {
        try {
            setLoading(true);
            await appState.selectedOrder?.payment.removeCard(cardInformation.id);
        } catch (err) {
            setLoading(false);
        }
    }

    const selected = cardInformation === appState.selectedOrder?.payment.selectedCard;
    const expMonth = cardInformation.exp_month;
    const expYear = cardInformation.exp_year * 12;
    const expired = expMonth + expYear
    const year = new Date().getFullYear() * 12;
    const month = new Date().getMonth() + 1;
    const now = year + month;

    const handleSelect = () => {
        if (deleting) {
            setDeleting(false)
        } else if (expired >= now) {
            appState.selectedOrder?.payment.selectCard(cardInformation);
        }
    }
    const handleDeleting = () => {
        setDeleting(true);
    }
    // modified by Bearer Web Developer start
    return (
        <>
            {/* { withDivider && <Divider />} */}
            <ListItem button onClick={handleSelect} selected={selected} 
            className={selected ? styles.cards_container_selected:styles.cards_container}>
                <ListItemText className={cardInformation.brand === "Visa" && selected ? styles.card_information_visa : ""}>
                    {getImageOfCart(cardInformation)}
                </ListItemText>
                <span className={styles.text}> XXXX {cardInformation.last4}</span>
                <span className={styles.text_date}>{cardInformation.exp_month}/{cardInformation.exp_year}</span>
                {expired < now &&
                    <span className={styles.expire_card}>Expired</span>
                }
                {expired >= now &&
                    <ListItemText></ListItemText>
                }
                {!deleting &&
                    <Button
                        onClick={handleDeleting}
                        disabled={loading}
                    >
                        <span className={styles.delete_button}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M5.83341 24.5V7H4.66675V4.66667H10.5001V3.5H17.5001V4.66667H23.3334V7H22.1667V24.5H5.83341ZM8.16675 22.1667H19.8334V7H8.16675V22.1667ZM10.5001 19.8333H12.8334V9.33333H10.5001V19.8333ZM15.1667 19.8333H17.5001V9.33333H15.1667V19.8333Z" fill="#E3E3E3" />
                            </svg>
                        </span>
                    </Button>
                }
                {loading && <DisabledClickLoading />}
                {deleting && <Button onClick={handleConfirmRemoveCard} className={styles.delete_confirm}>
                    Delete?</Button>}
            </ListItem>
        </>
    );
    // modified by Bearer Web Developer end
}));


const RequestIsNotValidForPayment: React.FC<{ payment: IPaymentState }> = ({ payment }) => {
    const handleInvalidRequestForPayment = () => {
        payment.order!.clear();
        payment.clear();
    }
    return (
        <>
            <Typography>Request is not valid</Typography>
            <DialogActions>
                <Button onClick={handleInvalidRequestForPayment}>OK</Button>
            </DialogActions>
        </>

    );
}

const FareUpdated: React.FC<{ payment: IPaymentState }> = ({ payment }) => {
    const handleFareUpdate = () => {
        payment.doFareUpdate();
    }
    return (
        <>
            <Typography>The fare for the current order has been from $A {payment.fareUpdateData?.oldPrice} to $A {payment.fareUpdateData?.transport_original_price}.</Typography>
            <DialogActions>
                <Button onClick={handleFareUpdate}>OK</Button>
            </DialogActions>
        </>
    )
}
const CouponIsNotValid: React.FC<{ payment: IPaymentState }> = ({ payment }) => {
    const handleInvalidCouponId = () => {
        payment.removeCouponId();
    }
    return (
        <>
            <Typography>This coupon is not valid to be used for this order.</Typography>
            <DialogActions>
                <Button onClick={handleInvalidCouponId}>OK</Button>
            </DialogActions>

        </>
    )
}

const DynamicPassword: React.FC<{ payment: IPaymentState }> = ({ payment }) => {
    const a = payment.dynamicPassword?.actionUrl;
    const b = payment.dynamicPassword?.actionType;
    React.useEffect(() => {
        window.open(a, '_parent')
    })
    return (
        <a href={a} />
    )
}
const SelectedCardHasProblem: React.FC<{ payment: IPaymentState }> = ({ payment }) => {
    const handleCardProblem = () => {
        payment.ShowCardProblem();
    }
    return (
        <>
            <Typography>Selected card has a problem </Typography>
            <Typography>{payment.cardProblem?.paymentStatus}</Typography>
            <DialogActions>
                <Button onClick={handleCardProblem}>OK</Button>
            </DialogActions>
        </>
    )
}


