import React from "react";
import { Button, Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { MessageEnum, useAppState } from "../../states/app-state";
import styles from "./ErrorsPopup.module.scss"; // added by Bearer Web Developer
// useStyles removed by Bearer Web Developer
const ErrorsPopup: React.FC = observer(() => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }

    return (
        <Dialog open={true} PaperProps={{ style: { borderRadius: "10px" } }}>
            <DialogContent>
                <DialogContentText>
                    {errorMessage.error === MessageEnum.Unknown && <UnknownError />}
                    {errorMessage.error === MessageEnum.ServerError && <ServerError />}
                    {errorMessage.error === MessageEnum.Error401 && <Error401 />}
                    {errorMessage.error === MessageEnum.Error402 && <Error402 />}
                    {errorMessage.error === MessageEnum.Error403 && <Error403 />}
                    {errorMessage.error === MessageEnum.Error406 && <Error406 />}
                    {errorMessage.error === MessageEnum.Error408 && <Error408 />}
                    {errorMessage.error === MessageEnum.Error410 && <Error410 />}
                    {errorMessage.error === MessageEnum.Error411 && <Error411 />}
                    {errorMessage.error === MessageEnum.Error413 && <Error413 />}
                    {errorMessage.error === MessageEnum.Error421 && <Error421 />}
                    {errorMessage.error === MessageEnum.Error422 && <Error422 />}
                    {errorMessage.error === MessageEnum.Error423 && <Error423 />}
                    {errorMessage.error === MessageEnum.Error424 && <Error424 />}
                    {errorMessage.error === MessageEnum.Error501 && <Error501 />}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )

})
export default ErrorsPopup;


const UnknownError: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    console.log({errorMessage}) // addded by Bearer Web Developer
    if (!errorMessage) {
        return null;
    }
    {/* addded by Bearer Web Developer start */ }
    if (Notification.permission === "denied") {
        appState?.setSnackMessage({
            message: "For continuous live updates on your delivery orders, enable browser notifications for this web app and refresh the page.",
            handleClick: () => {
                window.location.reload();
            }
        });
        return null;
    }
    {/* addded by Bearer Web Developer end */ }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            {/* <Typography>{errorMessage.message}</Typography> */} {/* commented by Bearer Web Developer */}
            <h3>Ops, its seems that there is a connectivity issue</h3> {/* added by Bearer Web Developer */}
        </div>
    )
    // modify by Bearer Web Developer end
}


const ServerError: React.FC = () => {
    const appState = useAppState();

    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleServerError = () => {
        appState.login.logout();
        window.location.replace(appState.webAppRemoteConfigParameters!.supportUrl);
    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleServerError} disableRipple={true} className={styles.button}><span>Support</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}
const Error401: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
        </div>
    )
    // modify by Bearer Web Developer end
}
const Error402: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleError402 = () => {
        appState.selectedOrder?.transport.removeMyOrder();
        appState.setErrorMessage(undefined);

    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError402} disableRipple={true} className={styles.button}><span>Ok</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}
const Error403: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
        </div>
    )
    // modify by Bearer Web Developer end
}
const Error408: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleError408 = () => {
        window.location.replace(appState.webAppRemoteConfigParameters!.supportUrl);
    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError408} disableRipple={true} className={styles.button}><span>Support</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}
const Error411: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleError411 = () => {
        appState.selectedOrder?.transport.removeMyOrder();
        appState.setErrorMessage(undefined);

    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError411} disableRipple={true} className={styles.button}><span>Ok</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}

const Error406: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleError406 = () => {
        window.location.replace(appState.webAppRemoteConfigParameters!.landingPageUrl);
    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError406} disableRipple={true} className={styles.button}><span>Learn More</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}


const Error410: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleClose = () => {
        appState.setErrorMessage(undefined);
    };
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleClose} disableRipple={true} className={styles.button}><span>Ok</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}

const Error413: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleError413 = () => {
        appState.setErrorMessage(undefined);
    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError413} disableRipple={true} className={styles.button}><span>Ok</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}

const Error421: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleError421 = () => {
        appState.selectedOrder?.transport.removeMyOrder();
        appState.setErrorMessage(undefined);

    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError421} disableRipple={true} className={styles.button}><span>Ok</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}


const Error422: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleError422 = () => {
        appState.setErrorMessage(undefined);

    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError422} disableRipple={true} className={styles.button}><span>Ok</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}


const Error423: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleError423 = () => {
        // appState.selectedOrder?.transport.removeMyOrder();
        // added by Bearer Web Developer start
        if (errorMessage?.extraInformation?.tabIndex || errorMessage?.extraInformation?.tabIndex === 0) {
            const { tabIndex } = errorMessage?.extraInformation;
            if (tabIndex !== appState.selectedOrderIndex)
                appState.selectOrder(tabIndex);
        }
        // added by Bearer Web Developer end
        // commented by Bearer Web Developer start
        // appState.selectedOrder?.searchRider.order?.clear();
        // appState.selectedOrder?.searchRider.clear();
        // commented by Bearer Web Developer end
        appState.selectedOrder?.transport.removeMyOrder(); // added by Bearer Web Developer
        appState.setErrorMessage(undefined);

    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError423} disableRipple={true} className={styles.button}><span>Ok</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}


const Error424: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }

    const handleError424 = () => {
        appState.selectedOrder?.transport.removeMyOrder();
        appState.setErrorMessage(undefined);

    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError424} disableRipple={true} className={styles.button}><span>Ok</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}

const Error501: React.FC = () => {
    const appState = useAppState();
    const { errorMessage } = appState;
    if (!errorMessage) {
        return null;
    }
    const handleError501 = () => {
        window.location.replace(appState.webAppRemoteConfigParameters!.supportNumber);
    }
    // modify by Bearer Web Developer start
    return (
        <div className={styles.text_popup}>
            <h3>{errorMessage.message}</h3>
            <Button onClick={handleError501} disableRipple={true} className={styles.button}><span>Support</span></Button>
        </div>
    )
    // modify by Bearer Web Developer end
}